import React, { Component } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getProducts } from '../../redux/actions/product'

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            partnumber: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).partnumber !== '' ? JSON.parse(localStorage.getItem('filterSets')).partnumber : '',
            vswr: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).vswr !== '' ? JSON.parse(localStorage.getItem('filterSets')).vswr : '',
            freqMin1: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).freqMin1 !== '' ? JSON.parse(localStorage.getItem('filterSets')).freqMin1 : '',
            freqMax1: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).freqMax1 !== '' ? JSON.parse(localStorage.getItem('filterSets')).freqMax1 : '',
            connector1: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).connector1 !== '' ? JSON.parse(localStorage.getItem('filterSets')).connector1 : '',
            connector2: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).connector2 !== '' ? JSON.parse(localStorage.getItem('filterSets')).connector2 : '',
            gainMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).gainMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).gainMin : '',
            psatMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).psatMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).psatMin : '',
            attenuation: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).attenuation !== '' ? JSON.parse(localStorage.getItem('filterSets')).attenuation : '',
            power: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).power !== '' ? JSON.parse(localStorage.getItem('filterSets')).power : '',
            inputVoltageMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).inputVoltageMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).inputVoltageMin : '',
            inputVoltageMax: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).inputVoltageMax !== '' ? JSON.parse(localStorage.getItem('filterSets')).inputVoltageMax : '',
            insertionLossMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).insertionLossMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).insertionLossMin : '',
            insertionLossMax: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).insertionLossMax !== '' ? JSON.parse(localStorage.getItem('filterSets')).insertionLossMax : '',
            insertionLossMin2: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).insertionLossMin2 !== '' ? JSON.parse(localStorage.getItem('filterSets')).insertionLossMin2 : '',
            insertionLossMax2: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).insertionLossMax2 !== '' ? JSON.parse(localStorage.getItem('filterSets')).insertionLossMax2 : '',
            dataRateInMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).dataRateInMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).dataRateInMin : '',
            dataRateOutMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).dataRateOutMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).dataRateOutMin : '',
            freqMin2: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).freqMin2 !== '' ? JSON.parse(localStorage.getItem('filterSets')).freqMin2 : '',
            freqMax2: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).freqMax2 !== '' ? JSON.parse(localStorage.getItem('filterSets')).freqMax2 : '',
            inputFreqMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).inputFreqMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).inputFreqMin : '',
            inputFreqMax: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).inputFreqMax !== '' ? JSON.parse(localStorage.getItem('filterSets')).inputFreqMax : '',
            outputFreqMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).outputFreqMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).outputFreqMin : '',
            outputFreqMax: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).outputFreqMax !== '' ? JSON.parse(localStorage.getItem('filterSets')).outputFreqMax : '',
            filterType: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).filterType !== '' ? JSON.parse(localStorage.getItem('filterSets')).filterType : '',
            substrateDiam: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).substrateDiam !== '' ? JSON.parse(localStorage.getItem('filterSets')).substrateDiam : '',
            thickness: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).thickness !== '' ? JSON.parse(localStorage.getItem('filterSets')).thickness : '',
            rfFreqMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).rfFreqMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).rfFreqMin : '',
            rfFreqMax: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).rfFreqMax !== '' ? JSON.parse(localStorage.getItem('filterSets')).rfFreqMax : '',
            loFreqMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).loFreqMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).loFreqMin : '',
            loFreqMax: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).loFreqMax !== '' ? JSON.parse(localStorage.getItem('filterSets')).loFreqMax : '',
            ifFreqMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).ifFreqMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).ifFreqMin : '',
            ifFreqMax: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).ifFreqMax !== '' ? JSON.parse(localStorage.getItem('filterSets')).ifFreqMax : '',
            txFreqMin: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).txFreqMin !== '' ? JSON.parse(localStorage.getItem('filterSets')).txFreqMin : '',
            txFreqMax: localStorage.getItem('filterSets') !== null && JSON.parse(localStorage.getItem('filterSets')).txFreqMax !== '' ? JSON.parse(localStorage.getItem('filterSets')).txFreqMax : '',
        }
        this.initialState = { ...this.state } 
    }

    handleInputChange(propertyName, event) {
        const data = this.state
        data[propertyName] = event.target.value
        this.setState(data)
    }

    resetFilters() {

        const data = {
            page_number: 1,
            category: this.props.category,
            manufacturer: this.props.manufacturer,
            pagesize: localStorage.getItem('pagesize')
        }

        this.setState(this.initialState)
        localStorage.removeItem('specfilter')
        this.props.getProducts(data)

    }

    /**
     * Save locally / web localstorage
     * The values will retain even if the webpage is refresh
     */
    saveLocalFilters() {
        let filterSets = {
            partnumber: this.state.partnumber,
            vswr: this.state.vswr,
            freqMin1: this.state.freqMin1,
            freqMax1: this.state.freqMax1,
            connector1: this.state.connector1,
            connector2: this.state.connector2,
            gainMin: this.state.gainMin,
            psatMin: this.state.psatMin,
            attenuation: this.state.attenuation,
            power: this.state.power,
            inputVoltageMin: this.state.inputVoltageMin,
            inputVoltageMax: this.state.inputVoltageMax,
            insertionLossMin: this.state.insertionLossMin,
            insertionLossMax: this.state.insertionLossMax,
            insertionLossMin2: this.state.insertionLossMin2,
            insertionLossMax2: this.state.insertionLossMax2,
            dataRateInMin: this.state.dataRateInMin,
            dataRateOutMin: this.state.dataRateOutMin,
            freqMin2: this.state.freqMin2,
            freqMax2: this.state.freqMax2,
            inputFreqMin: this.state.inputFreqMin,
            inputFreqMax: this.state.inputFreqMax,
            outputFreqMin: this.state.outputFreqMin,
            outputFreqMax: this.state.outputFreqMax,
            filterType: this.state.filterType,
            substrateDiam: this.state.substrateDiam,
            thickness: this.state.thickness,
            rfFreqMin: this.state.rfFreqMin,
            rfFreqMax: this.state.rfFreqMax,
            loFreqMin: this.state.loFreqMin,
            loFreqMax: this.state.loFreqMax,
            ifFreqMin: this.state.ifFreqMin,
            ifFreqMax: this.state.ifFreqMax,
            txFreqMin: this.state.txFreqMin,
            txFreqMax: this.state.txFreqMax
        }

        localStorage.setItem('filterSets', JSON.stringify(filterSets))
    }

    applyFilters() {
        let filter,  
            fmin = this.state.freqMin1 !== '' ? this.state.freqMin1 : '', 
            fmax = this.state.freqMax1 !== '' ?  this.state.freqMax1 : '', 
            fmin2 = this.state.freqMin2 !== '' ? this.state.freqMin2 : '', 
            fmax2 = this.state.freqMax2 !== '' ?  this.state.freqMax2 : '',
            ifmin = this.state.inputFreqMin !== '' ? this.state.inputFreqMin : '', 
            ifmax = this.state.inputFreqMax !== '' ? this.state.inputFreqMax : '', 
            ofmin = this.state.outputFreqMin !== '' ? this.state.outputFreqMin : '', 
            ofmax = this.state.outputFreqMax !== '' ? this.state.outputFreqMax : '',
            rffmin =  this.state.rfFreqMin !== '' ? this.state.rfFreqMin : '',
            rffmax = this.state.rfFreqMax !== '' ? this.state.rfFreqMax : '',
            lofmin = this.state.loFreqMin !== '' ? this.state.loFreqMin : '',
            lofmax = this.state.loFreqMax !== '' ? this.state.loFreqMax : '',
            iffmin = this.state.ifFreqMin !== '' ? this.state.ifFreqMin : '',
            iffmax = this.state.ifFreqMax !== '' ? this.state.ifFreqMax : '',
            txfmin = this.state.txFreqMin !== '' ? this.state.txFreqMin : '',
            txfmax = this.state.txFreqMax !== '' ? this.state.txFreqMax : ''

        localStorage.setItem('prange', 1)
        // const category = Number(localStorage.getItem('category'))
        const category = this.props.category

        const data = {
            page_number: 1,
            category: category,
            manufacturer: this.props.manufacturer,
            pagesize: localStorage.getItem('pagesize')
        }

        this.saveLocalFilters()

        if (category === 1) {
            filter = `product_partnumber=${this.state.partnumber}&vswr =${this.state.vswr}&fmin=${fmin}&fmax=${fmax}&conn1=${this.state.connector1}&conn2=${this.state.connector2}`
        } 

        if (category === 2) {
            filter = `product_partnumber=${this.state.partnumber}&gain=${this.state.gainMin}&psat=${this.state.psatMin}&fmin=${fmin}&fmax=${fmax}`
        }

        if (category === 3) {
            filter = `product_partnumber=${this.state.partnumber}&gain=${this.state.gainMin}&vswr=${this.state.vswr}&fmin=${fmin}&fmax=${fmax}`
        }

        if (category === 4) {
            filter = `product_partnumber=${this.state.partnumber}&fmin=${fmin}&fmax=${fmax}&atten=${this.state.attenuation}&pow=${this.state.power}&conn1=${this.state.connector1}&conn2=${this.state.connector2}`
        }

        if (category === 5 || category === 8 || category === 9 
            || category === 14 || category === 17 || category === 21 
            || category === 22 || category === 24 || category === 26
            || category === 27 || category === 28 || category === 29
            || category === 30 || category === 31 || category === 32
            || category === 33 || category === 34 || category === 35
            || category === 36 || category === 37 || category === 38
            || category === 40 || category === 41) {

            if (category === 40) {
                filter = `product_partnumber=${this.state.partnumber}&ivmin=${this.state.inputVoltageMin}&ivmax=${this.state.inputVoltageMax}&fmin=${fmin}&fmax=${fmax}`
            } else {
                filter = `product_partnumber=${this.state.partnumber}&fmin=${fmin}&fmax=${fmax}`
            }

        }

        if (category === 6) {
            filter = `product_partnumber=${this.state.partnumber}`
        }

        if (category === 7) {
            filter = `product_partnumber=${this.state.partnumber}&fmin=${fmin}&fmax=${fmax}&ilossmin=${this.state.insertionLossMin}&ilossmax=${this.state.insertionLossMax}&conn1=${this.state.connector1}&conn2=${this.state.connector2}`
        }

        if (category === 10 || category === 11) {
            filter = `product_partnumber=${this.state.partnumber}&fmin=${fmin}&fmax=${fmax}&ilossmax=${this.state.insertionLossMax}&pow=${this.state.power}&iso=${this.state.isolation}`
        }

        if (category === 12) {
            filter = `product_partnumber=${this.state.partnumber}&dri=${this.state.dataRateInMin}&dro=${this.state.dataRateOutMin}&ilossmax=${this.state.insertionLossMax}&pow=${this.state.power}&iso=${this.state.isolation}`
        }

        if (category === 13 || category === 20) {
            if (category === 13) {
            } else {
                filter = `product_partnumber=${this.state.partnumber}&fmin=${fmin}&fmax=${fmax}&ilossmax=${this.state.insertionLossMax}`
            }

        }

        if (category === 15) {
            filter = `product_partnumber=${this.state.partnumber}&pow=${this.state.power}&fmin=${fmin}&fmax=${fmax}&fmin2=${fmin2}&fmax2=${fmax2}&ilossmax=${this.state.insertionLossMax}&ilossma2=${this.state.insertionLossMax2}`
        }

        if (category === 16) {
            filter = `product_partnumber=${this.state.partnumber}&fmin=${fmin}&fmax=${fmax}&ftc=${this.state.filterType}&ilossmax=${this.state.insertionLossMax}`
        }

        if (category === 18 || category === 25) {
            filter = `product_partnumber=${this.state.partnumber}&ifmin=${ifmin}&ifmax=${ifmax}&ofmin=${ofmin}&ofmax=${ofmax}`
        }

        if (category === 19) {
            filter = `product_partnumber=${this.state.partnumber}&sd=${this.state.substrateDiam}&maxthick=${this.state.thickness}`
        }

        if (category === 23) {
            filter = `product_partnumber=${this.state.partnumber}&rffmin=${rffmin}&rffmax=${rffmax}&lofmin=${lofmin}&lofmax=${lofmax}&iffmin=${iffmin}&iffmax=${iffmax}`
        }

        if (category === 39) {
            filter = `product_partnumber=${this.state.partnumber}&rffmin=${rffmin}&rffmax=${rffmax}&txfmin=${txfmin}&txfmax=${txfmax}`
        }
        localStorage.setItem('specfilter', filter)
        this.props.getProducts(data, filter)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.category !== this.props.category) {
            this.resetFilters()
        }
    }

    render() {
        return(
            <React.Fragment>
                <h3>{this.props.category_name}</h3>
                {this.props.category === 1 &&
                    <Row>
                        <Col>
                           <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control 
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>VSWR Max</Form.Label>
                                <Form.Control
                                    value={this.state.vswr}
                                    onChange={this.handleInputChange.bind(this, 'vswr')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')}
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Connector 1 contains:</Form.Label>
                                <Form.Control
                                    value={this.state.connector1}
                                    onChange={this.handleInputChange.bind(this, 'connector1')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Connector 2 contains:</Form.Label>
                                <Form.Control
                                    value={this.state.connector2}
                                    onChange={this.handleInputChange.bind(this, 'connector2')}
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {this.props.category === 2 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Gain (dB)</Form.Label>
                                <Form.Control 
                                    value={this.state.gainMin}
                                    onChange={this.handleInputChange.bind(this, 'gainMin')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Psat (dBm)</Form.Label>
                                <Form.Control 
                                    value={this.state.psatMin}
                                    onChange={this.handleInputChange.bind(this, 'psatMin')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')} 
                                    placeholder="Maximum"
                                />
                            </div>
                        </Col>
                    </Row>
                }
                
                {this.props.category === 3 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                            />
                            </div>
                            <div className="my-2">
                                <Form.Label>Gain (dB)</Form.Label>
                                <Form.Control 
                                    value={this.state.gainMin}
                                    onChange={this.handleInputChange.bind(this, 'gainMin')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>VSWR</Form.Label>
                                <Form.Control
                                    value={this.state.vswr}
                                    onChange={this.handleInputChange.bind(this, 'vswr')} 
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                            <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')}
                                    placeholder="Maximum"
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {this.props.category === 4 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')} 
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Attenuation (dB)</Form.Label>
                                <Form.Control 
                                    value={this.state.attenuation}
                                    onChange={this.handleInputChange.bind(this, 'attenuation')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Power (dBm)</Form.Label>
                                <Form.Control 
                                    value={this.state.power}
                                    onChange={this.handleInputChange.bind(this, 'power')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Connector 1 contains</Form.Label>
                                <Form.Control
                                    value={this.state.connector1}
                                    onChange={this.handleInputChange.bind(this, 'connector1')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Connector 2 contains:</Form.Label>
                                <Form.Control
                                    value={this.state.connector2}
                                    onChange={this.handleInputChange.bind(this, 'connector2')}
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {(this.props.category === 5 || this.props.category === 8 || this.props.category === 9 
                    || this.props.category === 14 || this.props.category === 17 || this.props.category === 21 
                    || this.props.category === 22 || this.props.category === 24 || this.props.category === 26
                    || this.props.category === 27 || this.props.category === 28 || this.props.category === 29
                    || this.props.category === 30 || this.props.category === 31 || this.props.category === 32
                    || this.props.category === 33 || this.props.category === 34 || this.props.category === 35
                    || this.props.category === 36 || this.props.category === 37 || this.props.category === 38
                    || this.props.category === 40 || this.props.category === 41) &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            {this.props.category === 40 ?
                                <React.Fragment>
                                    <div className="my-2">
                                        <Form.Label>Input Voltage (V)</Form.Label>
                                        <Form.Control 
                                            value={this.state.inputVoltageMin}
                                            onChange={this.handleInputChange.bind(this, 'inputVoltageMin')}
                                            placeholder="Minimum"
                                        />
                                    </div>
                                    <div className="my-2">
                                        <Form.Label>Input Voltage (V)</Form.Label>
                                        <Form.Control 
                                            value={this.state.inputVoltageMax}
                                            onChange={this.handleInputChange.bind(this, 'inputVoltageMax')}
                                            placeholder="Maximum"
                                        />
                                    </div>
                                </React.Fragment>
                                : <React.Fragment>
                                    <div className="my-2">
                                        <Form.Label>Frequency (MHz)</Form.Label>
                                        <Form.Control 
                                            value={this.state.freqMin1}
                                            onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                            placeholder="Minimum"
                                        />
                                    </div>
                                    <div className="my-2">
                                        <Form.Label>Frequency (MHz)</Form.Label>
                                        <Form.Control
                                            value={this.state.freqMax1}
                                            onChange={this.handleInputChange.bind(this, 'freqMax1')}
                                            placeholder="Maximum"
                                        />
                                    </div>
                                </React.Fragment>
                            }
                         </Col>
                    </Row>
                }

                {(this.props.category === 6 || this.props.category === 42 || this.props.category === 43) &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {this.props.category === 7 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')}
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Insertion Loss (dB)</Form.Label>
                                <Form.Control 
                                    value={this.state.insertionLossMin}
                                    onChange={this.handleInputChange.bind(this, 'insertionLossMin')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Insertion Loss (dB)</Form.Label>
                                <Form.Control 
                                    value={this.state.insertionLossMax}
                                    onChange={this.handleInputChange.bind(this, 'insertionLossMax')}
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Connector 1 contains</Form.Label>
                                <Form.Control
                                    value={this.state.connector1}
                                    onChange={this.handleInputChange.bind(this, 'connector1')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Connector 2 contains:</Form.Label>
                                <Form.Control
                                    value={this.state.connector2}
                                    onChange={this.handleInputChange.bind(this, 'connector2')}
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {(this.props.category === 10 || this.props.category === 11) &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')} 
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Insertion Loss (dB)</Form.Label>
                                <Form.Control
                                    value={this.state.insertionLossMax}
                                    onChange={this.handleInputChange.bind(this, 'insertionLossMax')} 
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Power (dBm)</Form.Label>
                                <Form.Control 
                                    value={this.state.power}
                                    onChange={this.handleInputChange.bind(this, 'power')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Isolation (dB)</Form.Label>
                                <Form.Control
                                    value={this.state.isolation}
                                    onChange={this.handleInputChange.bind(this, 'isolation')}
                                    placeholder="Minimum"
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {this.props.category === 12 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Data Rate In (Gbps)</Form.Label>
                                <Form.Control 
                                    value={this.state.dataRateInMin}
                                    onChange={this.handleInputChange.bind(this, 'dataRateInMin')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Data Rate Out (Gbps) </Form.Label>
                                <Form.Control 
                                    value={this.state.dataRateOutMin}
                                    onChange={this.handleInputChange.bind(this, 'dataRateOutMin')}
                                    placeholder="Minimum"
                                />
                            </div>
                        </Col>
                       
                    </Row>
                }

                {(this.props.category === 13 || this.props.category === 20) &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')} 
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Insertion Loss (dB)</Form.Label>
                                <Form.Control
                                    value={this.state.insertionLossMax}
                                    onChange={this.handleInputChange.bind(this, 'insertionLossMax')}
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>VSWR</Form.Label>
                                <Form.Control 
                                    value={this.state.vswr}
                                    onChange={this.handleInputChange.bind(this, 'vswr')}
                                    placeholder="Maximum"
                                />
                            </div>
                            {this.props.category === 13 &&
                                <div className="my-2">
                                    <Form.Label>VSWR</Form.Label>
                                    <Form.Control 
                                        value={this.state.vswr}
                                        onChange={this.handleInputChange.bind(this, 'vswr')}
                                        placeholder="Maximum"
                                    />
                                </div>
                            }
                        </Col>
                    </Row>
                }
                
                {this.props.category === 15 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Power (dBm)</Form.Label>
                                <Form.Control 
                                    value={this.state.power}
                                    onChange={this.handleInputChange.bind(this, 'power')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Ch 1 Freq (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Ch 1 Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')} 
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Ch 2 Freq (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin2}
                                    onChange={this.handleInputChange.bind(this, 'freqMin2')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Ch 2 Freq (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.freqMax2}
                                    onChange={this.handleInputChange.bind(this, 'freqMax2')}
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Ch 1 Insertion Loss (dB)</Form.Label>
                                <Form.Control
                                    value={this.state.insertionLossMax}
                                    onChange={this.handleInputChange.bind(this, 'insertionLossMax')} 
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Ch 2 Insertion Loss (dB)</Form.Label>
                                <Form.Control 
                                    value={this.state.insertionLossMax2}
                                    onChange={this.handleInputChange.bind(this, 'insertionLossMax2')}
                                    placeholder="Maximum"
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {this.props.category === 16 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz) </Form.Label>
                                <Form.Control 
                                    value={this.state.freqMin1}
                                    onChange={this.handleInputChange.bind(this, 'freqMin1')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Frequency (MHz) </Form.Label>
                                <Form.Control 
                                    value={this.state.freqMax1}
                                    onChange={this.handleInputChange.bind(this, 'freqMax1')}
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Filter Type contains</Form.Label>
                                <Form.Control 
                                    value={this.state.filterType}
                                    onChange={this.handleInputChange.bind(this, 'filterType')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Insertion Loss (dB)</Form.Label>
                                <Form.Control
                                    value={this.state.insertionLossMax}
                                    onChange={this.handleInputChange.bind(this, 'insertionLossMax')} 
                                    placeholder="Maximum"
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {(this.props.category === 18 || this.props.category === 25) &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Input Freq (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.inputFreqMin}
                                    onChange={this.handleInputChange.bind(this, 'inputFreqMin')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Input Freq (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.inputFreqMax}
                                    onChange={this.handleInputChange.bind(this, 'inputFreqMax')}
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Output Freq (MHz)</Form.Label>
                                <Form.Control
                                     value={this.state.outputFreqMin}
                                     onChange={this.handleInputChange.bind(this, 'outputFreqMin')}
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Output Freq (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.outputFreqMax}
                                    onChange={this.handleInputChange.bind(this, 'outputFreqMax')}
                                    placeholder="Maximum"
                                />
                            </div>
                        </Col>
                    </Row>
                }

                {this.props.category === 19 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Substrate Diameter (in)</Form.Label>
                                <Form.Control
                                    value={this.state.substrateDiam}
                                    onChange={this.handleInputChange.bind(this, 'substrateDiam')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>Max Thickness (um)</Form.Label>
                                <Form.Control 
                                    value={this.state.thickness}
                                    onChange={this.handleInputChange.bind(this, 'thickness')}
                                    placeholder="Maximum"
                                />
                            </div>
                        </Col>
                    </Row>
                } 

                {this.props.category === 23 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>RF Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.rfFreqMin}
                                    onChange={this.handleInputChange.bind(this, 'rfFreqMin')} 
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>RF Freq (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.rfFreqMax}
                                    onChange={this.handleInputChange.bind(this, 'rfFreqMax')}
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>LO Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.loFreqMin}
                                    onChange={this.handleInputChange.bind(this, 'loFreqMin')} 
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>LO Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.loFreqMax}
                                    onChange={this.handleInputChange.bind(this, 'loFreqMax')}  
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>IF Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.ifFreqMin}
                                    onChange={this.handleInputChange.bind(this, 'ifFreqMin')}   
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>IF Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.ifFreqMax}
                                    onChange={this.handleInputChange.bind(this, 'ifFreqMax')} 
                                    placeholder="Maximum"
                                />
                            </div>
                        </Col>
                    </Row>
                }  

                {this.props.category === 39 &&
                    <Row>
                        <Col>
                            <div className="my-2">
                                <Form.Label>Part Number</Form.Label>
                                <Form.Control
                                    value={this.state.partnumber}
                                    onChange={this.handleInputChange.bind(this, 'partnumber')}
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>RF Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.rfFreqMin}
                                    onChange={this.handleInputChange.bind(this, 'rfFreqMin')}  
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>RF Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.rfFreqMax}
                                    onChange={this.handleInputChange.bind(this, 'rfFreqMax')} 
                                    placeholder="Maximum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>TX Freq (MHz)</Form.Label>
                                <Form.Control 
                                    value={this.state.txFreqMin}
                                    onChange={this.handleInputChange.bind(this, 'txFreqMin')} 
                                    placeholder="Minimum"
                                />
                            </div>
                            <div className="my-2">
                                <Form.Label>TX Freq (MHz)</Form.Label>
                                <Form.Control
                                    value={this.state.txFreqMax}
                                    onChange={this.handleInputChange.bind(this, 'txFreqMax')}  
                                    placeholder="Maximum"
                                />
                            </div>
                        </Col>
                    </Row>
                }  

                <Row className="my-3">
                    <Col>
                        <Button variant="rfbackdoor" 
                            className="my-2"
                            onClick={() => this.applyFilters()}
                            block>
                            Apply Filters
                        </Button>
                        <Button variant="rfbackdoor-outline" 
                            className="my-2"
                            onClick={() => this.resetFilters()}
                            block>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

Search.propTypes = {
    getProducts: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    products: state.product.products
})

export default connect(mapStateToProps, { getProducts })(Search)