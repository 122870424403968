import React, { Component } from 'react'
import { Badge, Button, Container, Col, Form, Image, Jumbotron, Row } from 'react-bootstrap'
import ImageModal from '../ImageModal'
import { VoltageStandingWaveRatioHelper } from '../../../helpers/toolHelpers'

class VoltageStandingWaveRatio extends Component {

    constructor(props) {
        super(props)
            this.state= {
                selected: 'vswr',
                value: '',
                unit: [
                        { name: 'VSWR', value: "vswr", defaultValue: '' },
                        { name: 'Return Loss (dB)', value: 'RL', defaultValue: '' },
                        { name: 'Reflction Coefficient (Γ)', value: 'rc', defaultValue: '' }
                      ],
                imageModal: false,
                imagePath: null
        }
    }

    imageModalHandler(path) {
        this.setState((state) => ({ 
            imageModal: !state.imageModal,
            imagePath: path
        }))
    }

    convertHandler() {

        let converted = VoltageStandingWaveRatioHelper(this.state.selected, this.state.value)

        this.setState({ unit: converted })
    }

    render () {

        let selected = this.state.unit.filter(option => option.value === this.state.selected)

        return (
            <>
                <Container>
                    <Jumbotron>
                        <h4 className="rf-text-blu">Voltage Standing Wave Ratio</h4>
                        <Row className="mt-5">
                            <Col md={12} lg={12} xl={6}>
                                <Form>
                                    { 
                                        selected.map((select, index) => ( 
                                            <Form.Group key={index}>
                                                <Form.Label>{select.name}</Form.Label>
                                                <Form.Control
                                                    type="number" 
                                                    placeholder={`Enter ${select.name}`}
                                                    onChange={(event) => this.setState({ value: event.target.value }, () => this.convertHandler()) }
                                                />
                                            </Form.Group>
                                        )) 
                                    }
                                </Form>
                                <div className="my-3">
                                    {this.state.unit.map((option, index) => (
                                        <Badge key={index} className="m-1 p-2 pointer"
                                        variant={this.state.selected === option.value ? 'info' : 'secondary'}
                                            onClick={ () => this.setState({ selected: option.value }, () => this.convertHandler()) }
                                        >
                                                {option.name}
                                        </Badge>
                                    ))}
                                </div>
                                <Form className="mt-5">
                                    {
                                        this.state.unit.filter(option => option.value !== this.state.selected)
                                            .map((opt, index) => (
                                                <Form.Group key={index}>
                                                    <Form.Label><i>{ opt.name }</i></Form.Label>
                                                    <Form.Control
                                                        placeholder={opt.name}
                                                        defaultValue={opt.defaultValue}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            )
                                        )
                                    }
                                </Form>
                            </Col>
                            <Col className="d-flex justify-content-center mt-4" md={12} lg={12} xl={6} >
                                <Image 
                                    src={`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/vswr-rl.png`} 
                                    className="rfbackdoor-tools-image" 
                                    onClick={() => this.imageModalHandler(`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/vswr-rl.png`)}
                                />
                            </Col>
                        </Row>
                    </Jumbotron>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">VSWR / Return Loss / Reflection Coefficient Formula</h4>
                    <h6 className="mt-5">
                        Given VSWR...
                    </h6>
                    <p className="mt-3">
                        <i>RL (Return Loss, dB)</i>
                    </p>
                    <h5 className="m-4"> RL = -20*log10 [ (VSWR-1) / (VSWR+1) ] </h5>
                    <p className="mt-3">
                        <i>Γ (Reflection Coefficient, unitless)</i>
                    </p>
                    <h5 className="m-4">Γ = (VSWR-1)/(VSWR+1)</h5>
                    <hr/>
                    <h6 className="mt-5">
                        Given Return Loss...
                    </h6>
                    <p className="mt-3">
                        <i>VSWR (unitless, but written as X:1, where X is the calculated value)</i>
                    </p>
                    <h5 className="m-4"> VSWR = [ (10^( RL / 20 ) +1) / (10^( RL / 20 ) -1)]  </h5>
                    <p className="mt-3">
                        <i>Γ (Reflection Coefficient, unitless)</i>
                    </p>
                    <h5 className="m-4">Γ = (10^[( RL / -20 )]</h5>
                    <hr/>
                    <h6 className="mt-5">
                        Given Gamma...
                    </h6>
                    <p className="mt-3">
                        <i>VSWR (unitless, but written as X:1, where X is the calculated value)</i>
                    </p>
                    <h5 className="m-4">VSWR = ( 1 + Γ ) / ( 1 - Γ )</h5>
                    <p className="mt-3">
                        <i>Γ (Reflection Coefficient, unitless)</i>
                    </p>
                    <h5 className="m-4">Γ = 20log10(Γ) (dB)</h5>
                    <Button className="mt-5" variant="rfbackdoor-round-outline" 
                        href={`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-files/tools-files/VSWR-Return-Loss-Conversion-Chart.pdf`}
                        target="_blank"
                    >
                            Download Reference Chart
                    </Button>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">What is VSWR?</h4>
                    <p className="mt-3">
                        VSWR, which is often pronounced as viz-waar, is an acronym for Voltage Standing Wave Ratio.  VSWR is a measurement that indicates
                         the severity of standing waves on a transmission line as a result of mismatch between the transmission line and the load 
                         (such as an antenna, for example).  The value of VSWR is always 1 or greater, where 1 represents a perfect match between 
                         the line and load with no standing waves present, and where a larger number indicates a larger amplitude standing wave on 
                         the transmission line. VSWR is typically written as a ratio in the form 1:x, where x is the VSWR value. However, when stated 
                         verbally, only the value itself is used.  
                    </p>
                    <p className="mt-3">
                        When there is a perfect match between the transmission line and the load, then all of the signal power is absorbed by 
                        the load and none will be reflected.  In this case, the only signals present on the transmission line are those 
                        that are traveling toward the load, and there are no standing waves present.
                    </p>
                    <p className="mt-3">
                        Standing waves are caused by signals that are reflected from a mismatched load and returned back 
                        down the transmission line.  As the reflected signals combine with the forward propagating waves, they create a 
                        distinct pattern.  Standing waves are always characterized by a pattern of alternating nodes and antinodes.  
                        Points where the wave is standing still are called nodes; points that oscillate between minimum and maximum amplitude 
                        values are called antinodes.   
                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">What is Return Loss?</h4>
                    <p className="mt-3">
                        Return Loss is also used to describe the match between a source and a load.  It provides a measure of how 
                        much power on the transmission line is reflected back from the load and returns down the line.  In the 
                        ideal case, there is a perfect match between the line and the load, and all of the power is transferred 
                        to (or absorbed by) the load. Thus, a lower number is better.  
 
                    </p>
                    <p className="mt-3">
                        The units for Return Loss are dB.  There is a negative sign in the Return Loss calculation, but the 
                        value of Return Loss should be, and always is, positive.  Mathematically, this is seen in the formula above.  
                        Since the argument in the log function will always be a decimal value, the result will be negative, and when 
                        multiplied by the negative sign out in front of the formula, the result is positive. 
                    </p>
                    <p className="mt-3">
                        Looking at it another way, the term "loss" in Return Loss already indicates a negative result. If this 
                        is then multiplied by a negative value, then it would be a negative loss, which is really a gain.  If 
                        instead it was called Return "Power," or something like that, then it would make sense to have it be a negative 
                        value. In fact, the opposite of Return Loss is called Reflectance (e.g., a 20 dB Return Loss is -20 dB Reflectance).
                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">What is Reflection Coefficient?</h4>
                    <p className="mt-3">
                        The Reflection Coefficient indicates the amount of signal power reflected back to the source.  It is 
                        equivalent to the complex S11 (or S22) value that comes from measuring scattering parameters.  It is 
                        represented by the greek capital letter gamma, 𝚪. As shown in the equations above, Return Loss can be 
                        calculated from 𝚪 by taking the log of the magnitude and multiplying by -20.
                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">VSWR or Return Loss?</h4>
                    <p className="mt-3">
                        Some use one, while some use the other, depending on the industry, application, training and comfort level.  
                        For example, <a href="https://www.amphenolrf.com/faq/technical/what-is-the-difference-between-return-loss-and-vswr.html" target="_blank" rel="noreferrer">one website </a> 
                        suggests that the cable industry generally prefers to use Return Loss, while the connector industry tends to use VSWR.  
                        The bottom line is that they are both just different ways of expressing the same thing.
                    </p>
                </Container>
                {
                    this.state.imageModal && 
                        <ImageModal 
                            title="Voltage Standing Wave Ratio" 
                            path={this.state.imagePath}
                            onHide={() => this.imageModalHandler()}/>
                }
            </>
        )
    }
}
export default VoltageStandingWaveRatio
