const initialState = {
    products: [],
    product_count: null,
    page_count: null,
    page_active: 1,
    loading: false
}

const product = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_PRODUCTS': 
            return {
                ...state,
                products: payload
            }
        case 'SET_PRODUCTS_COUNT':
            return {
                ...state,
                product_count: payload
            }
        case 'SET_PAGE_COUNT':
            return {
                ...state,                    
                page_count: payload
            }
        case 'SET_PAGE_ACTIVE': 
            return {
                ...state,
                page_active: payload
            }
        case 'UNSET_PRODUCTS': {
            return {
                ...state,
                product_count: null,
                products: [],
                page_count: null,
            }
        }
        case 'LOADING':
            return {
                ...state,
                loading: true
            }
        case 'LOADED': 
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default product