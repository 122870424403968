import React, { Component, Fragment } from 'react'
import { List } from 'react-content-loader'

class TableLoad extends Component {

    loadAnimation() {
        let content = []

        for (let i = 0; i < 10; i++) {
            content.push(
                <tr key={i}>
                    <td><List/></td>
                    <td><List/></td>
                    <td><List/></td>
                    <td><List/></td>
                    <td><List/></td>
                </tr>
            )
        }

        return content
    }

    render() {
        return (
            <Fragment>
                {this.loadAnimation()}                  
            </Fragment>
        )
    }
}

export default TableLoad