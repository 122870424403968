import React, { Component } from 'react'
import { Badge, Button, Container, Col, Image, Form, Row, Jumbotron } from 'react-bootstrap'
import ImageModal from '../ImageModal'
import { frequency_units, wavelength_units } from '../../../constant'
import { FrequencyToWavelengthHelper } from '../../../helpers/toolHelpers'
// import image from '../../../assets/images/tools-images/fw.png'

class FrequencyToWavelength extends Component {

    constructor(props) {
        super(props)
        this.state = {
            frequency: localStorage.getItem('calc_freq') && JSON.parse(localStorage.getItem('calc_freq')).frequency ? JSON.parse(localStorage.getItem('calc_freq')).frequency : null,
            wavelength: '',
            frequency_unit: localStorage.getItem('calc_freq') && JSON.parse(localStorage.getItem('calc_freq')).freq_unit ? JSON.parse(localStorage.getItem('calc_freq')).freq_unit : 'MHz',
            wavelength_unit: localStorage.getItem('calc_freq') && JSON.parse(localStorage.getItem('calc_freq')).wave_unit ? JSON.parse(localStorage.getItem('calc_freq')).wave_unit : 'in',
            error_message: null,
            imageModal: false,
            imagePath: null
        }
    }

    convertHandler(event) {

        const frequency = event ? event.target.value : this.state.frequency

        this.setState({ frequency: frequency })

        const wavelength = FrequencyToWavelengthHelper(frequency, this.state.frequency_unit, this.state.wavelength_unit)

        this.setState({ wavelength: wavelength })

        const freq_wave_calc = {
            frequency: frequency,
            freq_unit: this.state.frequency_unit,
            wave_unit: this.state.wavelength_unit
        }
        localStorage.setItem('freq_wave_calc', JSON.stringify(freq_wave_calc))
    }

    imageModalHandler(path) {
        this.setState((state) => ({ 
            imageModal: !state.imageModal,
            imagePath: path
        }))
    }

    componentDidMount() {
        this.convertHandler()
    }
       
    render() {
        return (
            <>
                <Container>
                    <Jumbotron>
                        <Container>
                            <h4 className="rf-text-blu">Frequency to Wavelength Calculator</h4>
                            <Row className="mt-3">
                                <Col md={12} lg={12} xl={6}>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Frequency (<i>f</i>)</Form.Label>
                                            <Form.Control 
                                                type="number" 
                                                placeholder="Enter Frequency" 
                                                value={this.state.frequency || ""}
                                                onChange={this.convertHandler.bind(this)}
                                            />
                                            <div className="my-3">
                                                {frequency_units.filter(freq => freq !== 'THz').map((units, index) => (
                                                    <Badge key={index} className="m-1 p-2 pointer" 
                                                        variant={this.state.frequency_unit === units ? 'info' : 'secondary'}
                                                        onClick={() => {
                                                            this.setState({ frequency_unit: units }, ()=> this.convertHandler())}}>
                                                            {units}
                                                    </Badge>
                                                ))}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mt-5">
                                            <Form.Label>Wavelength (λ)</Form.Label>
                                            <Form.Control 
                                                placeholder="λ"
                                                defaultValue={this.state.wavelength || ""}
                                                readOnly
                                            />
                                            <div className="my-3">
                                                {wavelength_units.map((units, index) => (
                                                    <Badge key={index} className="mx-1 p-2 pointer" 
                                                        variant={this.state.wavelength_unit === units ? 'info' : 'secondary'}
                                                        onClick={() => this.setState({ wavelength_unit: units }, () => this.convertHandler())}>
                                                            {units}
                                                    </Badge>
                                                ))}
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col className="d-flex justify-content-center mt-4" md={12} lg={12} xl={6}>
                                    <div className="d-flex align-items-md-start align-items-center justify-content-start " style={{ textAlign: 'center', width: '400px', height: '400px' }}> 
                                        <Image 
                                            src={`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/fw.png`} width="100%" 
                                            onClick={() => this.imageModalHandler(`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/fw.png`)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Jumbotron>
                </Container>
                <Container className="my-5">
                    <Row>
                        <Col>
                            <h4 className="rf-text-blu">Wavelength Formula</h4>
                            <p className="mt-3">
                                Given frequency, f, and velocity, c, (speed of light = 299,792,458 m/s), determine the wavelength, λ, of the signal.
                            </p>
                            <Image className="my-3" src={`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/wavelength-formula.png`}/>
                            <br/>
                            <Button className="mt-5" variant="rfbackdoor-round-outline" 
                                href={`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-files/tools-files/Frequency-to-Wavelength-Conversion-Chart.pdf`}
                                target="_blank">
                                Download Reference Chart
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">Why Convert Frequency to Wavelength?</h4>
                    <p className="mt-3">
                        Depending on your application, it may make more sense to speak about 
                        <a href="https://science.nasa.gov/ems/02_anatomy" target="blank"><i> electromagnetic waves </i></a>  
                        in terms of frequency or wavelength. And sometimes knowing both is useful. For example, when designing an antenna 
                        element for a given frequency, the wavelength plays a critical role in determining the physical size of 
                        the element. The calculator provided here is helpful if you need to know how to find the wavelength for a 
                        given frequency. Wavelength is typically denoted by the lowercase Greek letter lambda, λ, while the lowercase 
                        English letter f is used for frequency.
                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">Frequency Basics</h4>
                    <p className="mt-3">
                        Frequency refers to how often the signal, or waveform, is repeated during a period of time. The basic unit for 
                        specifying frequency is a cycle per second (1/s), which has been given the name of hertz (abbreviated Hz). This is 
                        so named in honor of <a href="https://www.britannica.com/biography/Heinrich-Hertz" target="blank"><i> Heinrich Hertz</i></a>, who is credited with proving the existence of electromagnetic waves. Given that 
                        a Hz is a single cycle per second, a 1 Hz signal repeats once every second, and a 3 MHz signal repeats 3 million 
                        times per second.
                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">Wavelength Basics</h4>
                    <p className="mt-3">
                        When a signal is traveling through a medium, the physical distance that the waveform travels in one cycle is known as the 
                        wavelength. Wavelength is a function of the speed at which the waveform is traveling and the frequency of the waveform. 
                        The properties of the medium affect the velocity of the waveform. For example, a signal will travel faster in air than 
                        it will through ice, or dirt. In the above wavelength calculation, it is assumed that the signal is traveling in a vacuum, 
                        where<a href="https://en.wikipedia.org/wiki/Velocity_factor" target="blank"><i> the velocity is equivalent to the speed of light</i></a>. 
                        This is a generally accepted assumption when estimating the waveform behavior in air, since the refractive index of air is very near to that of a vacuum. A more general version of the wavelength 
                        formula would include a<a href="https://www.sciencedirect.com/topics/materials-science/refractive-index" target="blank"><i> refractive index </i></a>
                        term to account for the variation in the propogation properties of the medium.
                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">Relationship Between Frequency and Wavelength</h4>
                    <p className="mt-3">
                        Frequency and wavelength are inversely proportional: as frequency increases, the wavelength decreases (and vice versa). 
                        Why is that? The speed at which a signal travels is not dependent on the frequency of the signal, so the wavelength must 
                        be changing in order to fit more (or fewer, in the case of a lower frequency signal) cycles into the same distance traveled.
                    </p>
                </Container>
                {
                    this.state.imageModal && 
                        <ImageModal 
                            title="Voltage Standing Wave Ratio" 
                            path={this.state.imagePath}
                            onHide={() => this.imageModalHandler()}
                        />
                }
            </>
        )
    }
}

export default FrequencyToWavelength