import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import Home from './components/views/Home'
import Products from './components/views/Products'
import Product from './components/views/Product'
import Manufacturers from './components/views/Manufacturers'
import Tools from './components/views/Tools'
import SpecificTools from './components/subcomponents/SpecificTools'
import ReactGA from 'react-ga'
import 'bootstrap/dist/css/bootstrap.min.css'

class App extends Component {

    componentDidMount() {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route exact path='/'>
                            <Home/>
                        </Route>
                        <Route exact path='/products/:manufacturer?/:category?'>
                            <Products/>
                        </Route>
                        <Route exact path='/product/:id/:partnumber'>
                            <Product/>
                        </Route>
                        <Route exact path='/manufacturers'>
                            <Manufacturers/>
                        </Route>
                        <Route exact path='/tools'>
                            <Tools/>
                        </Route>
                        <Route exact path='/tools/:title?'>
                            <SpecificTools/>
                        </Route>
                    </Switch>
                </Router>
            </Provider>
        )
    }
}

export default App
