/**
 * Constant values that are used repetitively inside the app
 */

export const products_introduction = [
    {
        'id': '0',
        'title': 'Step 1',
        'content': 'Get started by selecting a product category on the left.'
    },
    {
        'id': '1',
        'title': 'Step 2',
        'content': 'Use the filters that will appear above to narrow down the results list.'
    },
    {
        'id': '2',
        'title': 'Step 3',
        'content': 'Select a part from the search results to see more information.'
    }
]

export const manufacturers_introduction = [
    {
        'id': '0',
        'title': 'Search',
        'content': 'You can search specific manufacturers using the search bar at the top.'
    },
    {
        'id': '1',
        'title': 'Filter',
        'content': 'Use the filter dropdown to filter the manufacturers list depending on the specific product category of your choice.'
    }
]

export const tools_introduction = [
    {
        'id': '0',
        'title': 'Test',
        'content': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non ex odio. In hac habitasse platea dictumst. Suspendisse eleifend erat erat, quis tristique augue pellentesque quis.'
    },
    {
        'id': '1',
        'title': 'Test',
        'content': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non ex odio. In hac habitasse platea dictumst. Suspendisse eleifend erat erat, quis tristique augue pellentesque quis'
    }
]


export const calculator_tools = [
    {
        name: 'Frequency To Wavelength',
        value: 'frequency-to-wavelength',
        image: `${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/fw.png`,
        description: 'Frequency and wavelength are inversely proportional: as frequency increases, the wavelength decreases (and vice versa).'
    },
    {
        name: 'Frequency Unit Conversion (Hertz)',
        value: 'frequency-conversion',
        image: null
    },
    {
        name: 'Free Space Path Loss',
        value: 'free-space-path-loss',
        image: `${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/fspl.png`,
        description: 'Free space path loss (FSPL) is the amount of signal power loss that occurs between two antenna feed points as a radio wave travels through the air along a line-of-sight path. '
    },
    {
         name: 'Voltage Standing Wave Ratio',
         value: 'voltage-standing-wave-ratio',
        image: `${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/vswr-rl.png`,
        description: 'VSWR, which is often pronounced as viz-waar, is an acronym for Voltage Standing Wave Ratio. VSWR is a measurement that indicates the severity of standing waves on a transmission line as a result of mismatch between the transmission line and the load (such as an antenna, for example).'
    }
]


export const frequency_units = ['Hz', 'kHz', 'MHz', 'GHz', 'THz']
export const wavelength_units = ['m', 'cm', 'mm', 'ft', 'in']
export const distance_units = ['cm', 'm', 'km', 'in', 'ft', 'mi']