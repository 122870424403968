import axios from 'axios'

export const getProducts = ( data, filter ) => dispatch => {
    dispatch({ type: 'LOADING' })
    dispatch({ type: 'UNSET_PRODUCTS' })
    axios.get(`/v1/api/products/?size=${data.pagesize}&category=${data.category}&manufacturer__in=${data.manufacturer}&page=${data.page_number}&${filter}`).then(response => {
        localStorage.setItem('page_active', data.page_number)
        dispatch({ type: 'SET_PRODUCTS', payload: response.data.results })
        dispatch({ type: 'SET_PRODUCTS_COUNT', payload: response.data.count })
        dispatch({ type: 'SET_PAGE_COUNT', payload: Math.ceil(response.data.count/data.pagesize) })
        dispatch({ type: 'SET_PAGE_ACTIVE', payload: 0 })
        dispatch({ type: 'SET_PAGE_ACTIVE', payload: data.page_number })

        if (response.data.count === 0) {
            localStorage.setItem('prange', 1)
        }
    })
    //add error handler 
}

export const unsetProducts = () => dispatch => {
    dispatch({ type: 'LOADING'})
    dispatch({ type: 'UNSET_PRODUCTS' })
}

