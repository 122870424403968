import React, { Component, Fragment } from 'react'
import { withRouter } from "react-router"
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { BulletList } from 'react-content-loader'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTh, 
    faList, 
    faTable, 
    faSearch 
} from '@fortawesome/free-solid-svg-icons'
import { VscSortPrecedence } from "react-icons/vsc"
import { IoStorefrontOutline } from "react-icons/io5"
import { HiOutlineAdjustments } from "react-icons/hi"
import { 
    Badge,
    Button,
    CardDeck, 
    Col, 
    Container, 
    FormControl, 
    Image, 
    InputGroup, 
    Form, 
    Pagination, 
    Row
} from 'react-bootstrap'
import { getProducts, unsetProducts } from '../../redux/actions/product'
import Navigation from '../Navigation'
import Search from '../subcomponents/Search'
import ProductsTable from '../subcomponents/ProductsTable'
import GridLoad from '../subcomponents/LoadingAnimation/GridLoad'
import ListLoad from '../subcomponents/LoadingAnimation/ListLoad'
import GetStarted from '../subcomponents/GetStarted'
import Footer from '../subcomponents/Footer'
import noImagePreview from '../../assets/images/no-image-preview_2.png'
import noResult from '../../assets/images/no-result.png'
import { configureProductNumberForLink } from '../../helpers/productHelpers'
import { FiArrowUp, FiArrowLeft, FiArrowRight } from "react-icons/fi"

class Products extends Component {

    constructor(props) {
        super(props)
        this.resultScrollRef = React.createRef()  
        this.state = {
            mode: 'grid', // grid or list or table
            category: 0,
            category_name: '',
            manufacturer: [],
            manufacturers: [],
            categories: [],
            products: [],
            product_count: null,
            pages: [],
            page_count: null,
            page_active: null,
            range: null,
            page_batch: null,
            searchCategory: '',
            searchManuf: '',
            hide: true,
            product_filter_show: false,
            category_filter_show: false,
            manufacturer_filter_show: false,
            pagesize: 15,
            windowTop: 0
        }
    }

    resetHandler() {
        localStorage.clear()
        window.location.reload()
    }

    // handles the scroll to top button
    handleScrollFrame(event) {
        this.setState({
            windowTop: event.scrollTop
        })
    }


    handleInputChange(propertyName, event) {
        event.preventDefault() 

        const data = this.state
        data[propertyName] = event.target.value
        this.setState(data)
    }

    /**
     * 
     * @param {* Set the number of products showing in a single page} size 
     */
    pageSizeHandler(size) {
        this.setState({ pagesize :size }, () => {
            localStorage.setItem('pagesize', size)
            // return to page 1 when number of results is change
            // to prevent accessing the same page that may not occur depends
            // on how many results will show per page
            // for example page 5 will occur if 15 results per page but not on 90 results per page
            this.getProducts(1)
            this.PageRange('first')
        })
    }

    scrollPositionHandler() {
        window.onscroll = () => {
            this.setState({
                windowTop: window.pageYOffset
            })
        }
    }

    /**
     * 
     * @param { Set how the products are showing. Table, Grid and List view } mode 
     */
    changeView(mode) {
        this.setState({ mode: mode }, () => {
            localStorage.setItem('mode', mode)
        })
    }

    stateHandler(name, value) {
        const key = name
        let obj = {}
        obj[key] = value

        this.setState(obj)
    }

    Paginate() {
        let pages = []
        let range = Number(localStorage.getItem('prange')) ? Number(localStorage.getItem('prange')) : 1
        //change 5 to n where n is equal to number of page per batch
        let page_batch = Math.ceil(this.props.page_count / 5)

        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        this.setState({
            range: Number(localStorage.getItem('prange')),
            //change 5 to n where n is equal to number of page per batch
            page_batch: page_batch
        })

        //change 5 to n where n is equal to number of page per batch
        let page_limit = range * 5
        let page_start = page_limit - 4

        if (range === page_batch) {
            for (let number = page_start; number <= this.props.page_count; number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === this.props.page_active} onClick={() => this.getProducts(number)}>
                        {number}
                    </Pagination.Item>
                )
            }
            this.setState({ pages: pages }) 
        } else {
            for (let number = page_start; number <= page_limit; number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === this.props.page_active} onClick={() => this.getProducts(number)}>
                        {number}
                    </Pagination.Item>
                )
            }
            this.setState({ pages: pages }) 
        }
    }

    PageRange(page_activity) {
        let range

        switch (page_activity) {
            case 'first': 
                range = localStorage.setItem('prange', 1)
                this.Paginate()
                break
            case 'prev':
                range = Number(localStorage.getItem('prange'))
                let prev_page = range - 1
                localStorage.setItem('prange', prev_page)
                this.Paginate()
                break
            case 'next':
                range = Number(localStorage.getItem('prange')) ? Number(localStorage.getItem('prange')) : 1
                let next_page = range + 1
                localStorage.setItem('prange', next_page)
                this.Paginate()
                break
            case 'last':
                //change 5 to n where n is eqaul to number of page per batch
                range = Math.ceil(this.props.page_count / 5)
                localStorage.setItem('prange', range)
                this.Paginate()
                break
            default:
                this.Paginate()
                return range
        }
    }

    nextPage(state) {
        let temp_page_active

        if (state == "next") {
            temp_page_active = this.props.page_active + 1
        } else {
            temp_page_active = this.props.page_active - 1
        }
        
        let temp_range = Math.ceil(temp_page_active / 5)
        let range = Number(localStorage.getItem('prange'))

        if (range < temp_range) {
            this.PageRange('next')
        } else if (range > temp_range) {
            this.PageRange('prev')
        }

        this.getProducts(temp_page_active)
    }

    filterCategory(id, name) {
        this.setState((state) => ({ 
            category_name: name,
            category: id,
            mode: id === 0 ? 'grid' : state.mode
        }), () => {
            const category_obj = [{
                id: id,
                name: name
            }]
            localStorage.setItem('category', JSON.stringify(category_obj))
            this.getProducts(1)
        })

        this.getManufacturers()
    }

    filterManufacturers(id, remove) {
        const exist = this.state.manufacturer.includes(id)
        let selected_manuf = []

        if (id === 0) {
            if (remove) {
                this.setState({ manufacturer: selected_manuf }, () => {
                    localStorage.setItem('manuf', JSON.stringify(this.state.manufacturer))
                    this.getProducts(1)
                })
            } else {
                selected_manuf.push(0)
                this.state.manufacturers.map(manuf => (
                    selected_manuf.push(manuf.id)
                ))
                this.setState({ manufacturer: selected_manuf }, () => {
                    localStorage.setItem('manuf', JSON.stringify(this.state.manufacturer))
                    this.getProducts(1)
                })
            }
            return
        }

        if (exist) {
            const all =  this.state.manufacturer.includes(0)
            let __filtered_manuf = this.state.manufacturer.filter(manuf => manuf !== 0)

            if (all) {
                __filtered_manuf = __filtered_manuf.filter(manuf => manuf !== id)
                this.setState({ manufacturer: __filtered_manuf }, () => {
                    localStorage.setItem('manuf', JSON.stringify(this.state.manufacturer))
                    this.getProducts(1)
                })
            } else {
                this.setState((state) => ({
                    manufacturer: state.manufacturer.filter(manuf => manuf !== id)
                }), () => {
                    localStorage.setItem('manuf', JSON.stringify(this.state.manufacturer))
                    this.getProducts(1) 
                })
            }
        } else {
            let manuf_arr = this.state.manufacturer.concat(id)

            if (manuf_arr.length === this.state.manufacturers.length) {
                manuf_arr = manuf_arr.concat(0)
            }
            this.setState({ manufacturer: manuf_arr }, () => {
                localStorage.setItem('manuf', JSON.stringify(this.state.manufacturer))
                this.getProducts(1)
            })
        }

        localStorage.setItem('prange', 1)
    }

    getProducts(page, filter) {
        let page_number
        page === null ? page_number = 1 : page_number = page
        const manuf_all = this.state.manufacturer.includes(0)

        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.resultScrollRef.current.scrollToTop()
        
        const data = {
            page_number: page_number,
            category: this.state.category === 0 ? '' : this.state.category,
            manufacturer: manuf_all ? [] : this.state.manufacturer,
            product_count: null,
            pagesize: this.state.pagesize
        }

        this.setState({
            pages: [],
            range: null,
            page_batch: null
        })

        if (filter == null) {
            const initial_filter = localStorage.getItem('specfilter')
            filter = initial_filter
        }

        this.props.unsetProducts()
        this.props.getProducts(data, filter)
    }

    getCategory(event) {
        /**
         * TODO: reset filter every change or retain filter even after change
         */
        if (event) {
            event.preventDefault()
        }

        let search
        let categories = []
        let categories_sort = []
        search = this.state.searchCategory !== '' ? this.state.searchCategory : ''

        axios.get(`v1/api/category/?&search=${search}`).then(response => {
            response.data.count !== 0 ?
                search !== '' ? 
                    categories = response.data
                : categories = [...this.state.categories, ...response.data]
            : categories = response.data

            categories_sort = categories.sort((a, b) => a.name.localeCompare(b.name))

            if (response.data.count === 0) {
                this.setState({ categ_no_results : true })
            }

            this.setState({ 
                categories: categories_sort
            })
        }) 
    }

    getManufacturers(event) {
        /**
         * TODO: reset filter every change or retain filter even after change
         */
        if (event) {
            event.preventDefault()
        }

        let search
        let manufacturers = []
        let manufacturers_sort = []

        if (this.state.category !== null) {
            axios.get(`/v1/api/manufacturer-active/??category=${this.state.category}`).then(response => {
                if (response.data.count !== 0) {
                    response.data.map(manuf => {
                        manufacturers = [...manufacturers, manuf.manufacturer_details]
                    })

                    manufacturers_sort = manufacturers.sort((a, b) => a.name.localeCompare(b.name))
                } else {
                    this.setState({ manuf_no_results : true })
                }
                this.setState({ 
                    manufacturers: Array.from(new Set(manufacturers_sort))
                })
            })
        } else {
            search = this.state.searchManuf !== '' ? this.state.searchManuf : ''
    
            axios.get(`v1/api/manufacturers/?&search=${search}`).then(response => {
                response.data.count !== 0 ?
                    search !== '' ? 
                        manufacturers = response.data
                    : manufacturers = [...this.state.manufacturers, ...response.data]
                : manufacturers = response.data
    
                manufacturers_sort = manufacturers.sort((a, b) => a.name.localeCompare(b.name))
    
                if (response.data.count === 0) {
                    this.setState({ manuf_no_results : true })
                }

                this.setState({ 
                    manufacturers: Array.from(new Set(manufacturers_sort))
                })
            })   
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.page_active !== this.props.page_active) {
            this.Paginate()
        }

        if (prevState.manufacturers !== this.state.manufacturers) {
            if (this.state.manufacturer.includes(0)) {
                this.filterManufacturers(0)
            }
        }
    }

    componentDidMount() {
        const { match } = this.props
        let category, manufacturer, category_name
        const categ = JSON.parse(localStorage.getItem('category'))

        if (typeof match.params.category === 'undefined') {
            category = categ !== null ? Number(categ[0].id) : 0
            category_name = categ !== null ? categ[0].name : null
        } else {
            category = Number(match.params.category)
        }
        // typeof match.params.category === 'undefined' ?
        //     category = categ !== null ? Number(categ[0].id) : 0
        // : category = Number(match.params.category)
        if (typeof match.params.manufacturer !== 'undefined') {
            manufacturer = [Number(match.params.manufacturer)]
        } else {
            manufacturer = JSON.parse(localStorage.getItem('manuf'))
        }

        const initial_page = Number(localStorage.getItem('page_active'))
        const initial_category = category
        const initial_category_name = category_name
        const initial_manufacturer = manufacturer
        const initial_mode = localStorage.getItem('mode')
        const initial_filter = localStorage.getItem('specfilter')
        const initial_pagesize = localStorage.getItem('pagesize')

        initial_mode !== null && this.setState({ mode: initial_mode })
        
        this.setState((state) => ({
            pagesize: initial_pagesize ? initial_pagesize : 15,
            category: initial_category !== null ? initial_category : state.category,
            category_name: initial_category_name !== null ? initial_category_name : state.category_name,
            manufacturer: initial_manufacturer && initial_manufacturer.length !== 0 ? initial_manufacturer : state.manufacturer
        }), () => {
            initial_category !== 0 &&
                this.getProducts(initial_page !== 0 ? initial_page : 1, initial_filter)
        })

        // this.scrollPositionHandler()
        this.getCategory() 
        this.getManufacturers()

        this.Paginate()
    }

    render() {

        return (
            <Fragment>
                <Navigation/>
                <div className={this.state.windowTop > 500 ? 'scroll-to-top-button' : "scroll-to-top-button-hide"} onClick={() => this.resultScrollRef.current.scrollToTop()}>
                    <FiArrowUp
                        color="white"
                        size={30}
                    />
                </div>
                <Container className="my-sm-2 px-sm-3 my-md-3 px-md-5" fluid>
                    <Row className="rfbackdoor-desktop-hide">
                        <Col className="d-flex flex-row justify-content-center my-3 rfbackdoor-desktop-hide">
                            {
                                this.state.category !== 0 &&
                                    <Button 
                                        variant={this.state.product_filter_show ? 'rfbackdoor-round' : 'rfbackdoor-round-outline'}
                                        className="mx-2"
                                        onClick={() => this.stateHandler('product_filter_show', !this.state.product_filter_show)}
                                    >
                                        <HiOutlineAdjustments/>
                                    </Button>
                            }
                            <Button 
                                variant={this.state.category_filter_show ? 'rfbackdoor-round' : 'rfbackdoor-round-outline'}
                                className="mx-2"
                                onClick={() => this.stateHandler('category_filter_show', !this.state.category_filter_show)}
                            >
                                <VscSortPrecedence/>
                            </Button>
                            <Button 
                                variant={this.state.manufacturer_filter_show ? 'rfbackdoor-round' : 'rfbackdoor-round-outline'}
                                className="mx-2"
                                onClick={() => this.stateHandler('manufacturer_filter_show', !this.state.manufacturer_filter_show)}
                            >
                                <IoStorefrontOutline/>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="rfbackdoor-desktop-hide">
                        <Col className="rfbackdoor-desktop-hide">
                            {
                                this.state.product_filter_show &&
                                    <Container className="box round mt-4 mb-2 p-4" fluid>
                                        <h6 className="justify-content-start">Product Filter</h6>
                                        <Search category={this.state.category} manufacturer={this.state.manufacturer}
                                            category_name={this.state.category_name}
                                        />
                                    </Container>
                            }
                            {
                                this.state.category_filter_show &&
                                    <Container className="box round mt-4 mb-2 p-4" fluid>
                                        <h6 className="justify-content-start">Category</h6>
                                        <Form
                                            onSubmit={this.getCategory.bind(this)}
                                        >
                                            <Form.Group controlId="exampleForm.SelectCustom">
                                                <InputGroup size="sm" className="mb-3">
                                                    <FormControl
                                                        placeholder="Search"
                                                        aria-label="Search"
                                                        aria-describedby="basic-addon2"
                                                        onChange={this.handleInputChange.bind(this, 'searchCategory')}
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text id="basic-addon2" onClick={ () => this.getCategory() }>
                                                            <FontAwesomeIcon icon={faSearch} size="sm"/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Form.Group>
                                        </Form>
                                        <Scrollbars 
                                            autoHeight 
                                            autoHeightMin={0} 
                                            autoHeightMax={300} 
                                            thumbMinSize={30} 
                                            universal={true}
                                        >
                                            <Form>
                                                {
                                                    this.state.categories.length !== 0 ?
                                                        this.state.categories.map((category, index) => (
                                                            <Form.Check key={index}>
                                                                <Form.Check.Input 
                                                                    type="checkbox" 
                                                                    onChange={() => this.filterCategory(category.id, category.name)}
                                                                    checked={this.state.category === category.id}/>
                                                                    <Form.Check.Label>{category.name}</Form.Check.Label>
                                                                </Form.Check>
                                                        ))
                                                    : this.state.categ_no_results ? 
                                                        <Fragment>
                                                            <p><i>No results found</i></p>
                                                        </Fragment>
                                                    : <Fragment>
                                                        <BulletList />
                                                        <BulletList />
                                                        <BulletList />
                                                        <BulletList />
                                                    </Fragment>
                                                }
                                            </Form>
                                        </Scrollbars>
                                    </Container>
                            }
                            {
                                this.state.manufacturer_filter_show && 
                                    <Container className="box round mt-4 mb-2 p-4" fluid>
                                        <h6 className="justify-content-start">Manufacturers</h6>
                                        <Form
                                            onSubmit={this.getManufacturers.bind(this)}
                                        >
                                            <Form.Group controlId="exampleForm.SelectCustom">
                                                <InputGroup size="sm" className="mb-3">
                                                    <FormControl
                                                        placeholder="Search"
                                                        aria-label="Search"
                                                        aria-describedby="basic-addon2"
                                                        onChange={this.handleInputChange.bind(this, 'searchManuf')}
                                                        disabled = {this.state.category === 0 ? true : false}
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text id="basic-addon2" onClick={ () => this.getManufacturers() }>
                                                            <FontAwesomeIcon icon={faSearch} size="sm"/>
                                                        </InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Form.Group>
                                        </Form>
                                        <Scrollbars 
                                            autoHeight 
                                            autoHeightMin={0} 
                                            autoHeightMax={300} 
                                            thumbMinSize={30} 
                                            universal={true}
                                        >
                                            <Form>
                                                {
                                                    this.state.manufacturer.length !== 0 && 
                                                        <Fragment>
                                                            <p><b>Selected</b></p>
                                                            {
                                                                this.state.manufacturer.includes(0) &&
                                                                    <Form.Check>
                                                                        <Form.Check.Input type="checkbox" 
                                                                            checked={this.state.manufacturer.includes(0) ? true : false}
                                                                            onChange={() => this.filterManufacturers(0, this.state.manufacturer.includes(0) ? true : false)}/>
                                                                        <Form.Check.Label>All</Form.Check.Label>
                                                                    </Form.Check>
                                                            }
                                                            {
                                                                this.state.manufacturers.filter(manuf => this.state.manufacturer.includes(manuf.id))
                                                                    .map((manufacturer, index) => (
                                                                        <Form.Check key={index}>
                                                                            <Form.Check.Input type="checkbox" 
                                                                                checked={this.state.manufacturer.includes(manufacturer.id) ? true : false}
                                                                                onChange={() => this.filterManufacturers(manufacturer.id)}/>
                                                                            <Form.Check.Label>{manufacturer.name}</Form.Check.Label>
                                                                        </Form.Check>
                                                                    )
                                                                )
                                                            }
                                                            <hr/>
                                                        </Fragment>
                                                }
                                                {
                                                    this.state.manufacturers.length !== 0 ?
                                                        <Fragment>
                                                            {
                                                                !this.state.manufacturer.includes(0) &&
                                                                    <Form.Check>
                                                                        <Form.Check.Input type="checkbox" 
                                                                            checked={this.state.manufacturer.includes(0) ? true : false}
                                                                            onChange={() => this.filterManufacturers(0, this.state.manufacturer.includes(0) ? true : false)}
                                                                            disabled = {this.state.category === 0 ? true : false}/>
                                                                        <Form.Check.Label>All</Form.Check.Label>
                                                                    </Form.Check>
                                                            }
                                                            {
                                                                this.state.manufacturers.filter(manuf => !this.state.manufacturer.includes(manuf.id))
                                                                    .map((manufacturer, index) => (
                                                                        <Form.Check key={index}>
                                                                            <Form.Check.Input type="checkbox" 
                                                                                checked={this.state.manufacturer.includes(manufacturer.id) ? true : false}
                                                                                onChange={() => this.filterManufacturers(manufacturer.id)}
                                                                                disabled = {this.state.category === 0 ? true : false}/>
                                                                            <Form.Check.Label>{manufacturer.name}</Form.Check.Label>
                                                                        </Form.Check>
                                                                    ))
                                                            }
                                                        </Fragment>
                                                    : this.state.manuf_no_results ? 
                                                        <Fragment>
                                                            <p><i>No results found</i></p>
                                                        </Fragment>
                                                    : <Fragment>
                                                        <BulletList />
                                                        <BulletList />
                                                        <BulletList />
                                                        <BulletList />
                                                    </Fragment>
                                                }
                                            </Form>
                                        </Scrollbars>
                                    </Container>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} lg={4} xl={3} className="rfbackdoor-mobile-hide">
                            <Scrollbars
                                className="box round"
                                autoHeight 
                                autoHide
                                autoHeightMin={'80vh'} 
                                autoHeightMax={'80vh'} 
                                thumbMinSize={30} 
                                universal={true} 
                                md={5} lg={4} xl={3}
                            >
                            {/* <Container className="box round mb-2 p-4" fluid> */}
                            <Container className="px-4 pt-4" fluid>
                                <h6 className="justify-content-start">Results per page</h6>
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                    <span>
                                        <input 
                                            type="radio" 
                                            name="pagesize" 
                                            value={15}
                                            onClick={() => this.pageSizeHandler(15)}
                                            checked={this.state.pagesize == 15}
                                        />
                                        <label className="ml-2" for="15">15</label>
                                    </span>
                                    <span>
                                        <input 
                                            type="radio" 
                                            name="pagesize" 
                                            value={30}
                                            onClick={() => this.pageSizeHandler(30)}
                                            checked={this.state.pagesize == 30}
                                        />
                                        <label className="ml-2" for="30">30</label>
                                    </span>
                                    <span>
                                        <input 
                                            type="radio" 
                                            name="pagesize" 
                                            value={90}
                                            onClick={() => this.pageSizeHandler(90)}
                                            checked={this.state.pagesize == 90}
                                        />
                                        <label className="ml-2" for="90">90</label>
                                    </span>
                                </div>
                                <hr/>
                            </Container>
                            {/* start of search bar  */}
                            {
                                this.state.category !== 0 &&
                                    // <Container className="box round mt-4 mb-2 p-4" fluid>
                                    <Container className="mb-2 px-4 pt-4" fluid>
                                        <h6 className="justify-content-start">Product Filter</h6>
                                        <Search category={this.state.category} manufacturer={this.state.manufacturer}
                                            category_name={this.state.category_name}/>
                                            <hr/>
                                    </Container>
                            }
                            {/* end of search bar */}
                            {/* <Container className="box round mt-4 mb-2 p-4 rfbackdoor-mobile-hide" fluid> */}
                            <Container className="mt-4 mb-2 px-4 pt-4 rfbackdoor-mobile-hide" fluid>
                                <h6 className="justify-content-start">Category</h6>
                                <Form
                                    onSubmit={this.getCategory.bind(this)}
                                >
                                    <Form.Group>
                                        <InputGroup size="sm" className="mb-3">
                                            <FormControl
                                                placeholder="Search"
                                                aria-label="Search"
                                                aria-describedby="basic-addon2"
                                                onChange={this.handleInputChange.bind(this, 'searchCategory')}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text id="basic-addon2" onClick={ () => this.getCategory() }>
                                                    <FontAwesomeIcon icon={faSearch} size="sm"/>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                                <Scrollbars 
                                    autoHeight 
                                    autoHeightMin={0} 
                                    autoHeightMax={300} 
                                    thumbMinSize={30} 
                                    universal={true}
                                >
                                    <Form>
                                        {
                                            this.state.categories.length !== 0 ?
                                                this.state.categories.map((category, index) => (
                                                    <Form.Check key={index}>
                                                        <Form.Check.Input 
                                                            type="checkbox" 
                                                            onChange={() => this.filterCategory(category.id, category.name)}
                                                            checked={this.state.category === category.id}/>
                                                            <Form.Check.Label>{category.name}</Form.Check.Label>
                                                        </Form.Check>
                                                ))
                                            : this.state.categ_no_results ? 
                                                <Fragment>
                                                    <p><i>No results found</i></p>
                                                </Fragment>
                                            : <Fragment>
                                                <BulletList />
                                                <BulletList />
                                                <BulletList />
                                                <BulletList />
                                            </Fragment>
                                        }
                                    </Form>
                                </Scrollbars>
                                <hr/>
                            </Container>
                            {/* <Container className="box round mt-4 mb-2 p-4 rfbackdoor-mobile-hide" fluid> */}
                            <Container className="mt-4 mb-2 px-4 pt-4 rfbackdoor-mobile-hide" fluid>
                                <h6 className="justify-content-start">Manufacturers</h6>
                                <Form
                                    onSubmit={this.getManufacturers.bind(this)}
                                >
                                    <Form.Group>
                                        <InputGroup size="sm" className="mb-3">
                                            <FormControl
                                                placeholder="Search"
                                                aria-label="Search"
                                                aria-describedby="basic-addon2"
                                                onChange={this.handleInputChange.bind(this, 'searchManuf')}
                                                disabled = {this.state.category === 0 ? true : false}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text id="basic-addon2" onClick={ () => this.getManufacturers() }>
                                                    <FontAwesomeIcon icon={faSearch} size="sm"/>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                                <Scrollbars 
                                    autoHeight 
                                    autoHeightMin={0} 
                                    autoHeightMax={300} 
                                    thumbMinSize={30} 
                                    universal={true}
                                >
                                    <Form>
                                        {
                                            this.state.manufacturer.length !== 0 && 
                                                <Fragment>
                                                    <p><b>Selected</b></p>
                                                    {
                                                        this.state.manufacturer.includes(0) &&
                                                            <Form.Check>
                                                                <Form.Check.Input type="checkbox" 
                                                                    checked={this.state.manufacturer.includes(0) ? true : false}
                                                                    onChange={() => this.filterManufacturers(0, this.state.manufacturer.includes(0) ? true : false)}/>
                                                                <Form.Check.Label>All</Form.Check.Label>
                                                            </Form.Check>
                                                    }
                                                    {
                                                        this.state.manufacturers.filter(manuf => this.state.manufacturer.includes(manuf.id))
                                                            .map((manufacturer, index) => (
                                                                <Form.Check key={index}>
                                                                    <Form.Check.Input type="checkbox" 
                                                                        checked={this.state.manufacturer.includes(manufacturer.id) ? true : false}
                                                                        onChange={() => this.filterManufacturers(manufacturer.id)}/>
                                                                    <Form.Check.Label>{manufacturer.name}</Form.Check.Label>
                                                                </Form.Check>
                                                            )
                                                        )
                                                    }
                                                    <hr/>
                                                </Fragment>
                                        }
                                        {
                                            this.state.manufacturers.length !== 0 ?
                                                <Fragment>
                                                    {
                                                        !this.state.manufacturer.includes(0) &&
                                                            <Form.Check>
                                                                <Form.Check.Input type="checkbox" 
                                                                    checked={this.state.manufacturer.includes(0) ? true : false}
                                                                    onChange={() => this.filterManufacturers(0, this.state.manufacturer.includes(0) ? true : false)}
                                                                    disabled = {this.state.category === 0 ? true : false}/>
                                                                <Form.Check.Label>All</Form.Check.Label>
                                                            </Form.Check>
                                                    }
                                                    {
                                                        this.state.manufacturers.filter(manuf => !this.state.manufacturer.includes(manuf.id))
                                                            .map((manufacturer, index) => (
                                                                <Form.Check key={index}>
                                                                    <Form.Check.Input type="checkbox" 
                                                                        checked={this.state.manufacturer.includes(manufacturer.id) ? true : false}
                                                                        onChange={() => this.filterManufacturers(manufacturer.id)}
                                                                        disabled = {this.state.category === 0 ? true : false}/>
                                                                    <Form.Check.Label>{manufacturer.name}</Form.Check.Label>
                                                                </Form.Check>
                                                            ))
                                                    }
                                                </Fragment>
                                            : this.state.manuf_no_results ? 
                                                <Fragment>
                                                    <p><i>No results found</i></p>
                                                </Fragment>
                                            : <Fragment>
                                                <BulletList />
                                                <BulletList />
                                                <BulletList />
                                                <BulletList />
                                            </Fragment>
                                        }
                                    </Form>
                                </Scrollbars>
                            </Container>
                            </Scrollbars>
                        </Col>
                        <Col md={7} lg={8} xl={9}>
                            <Scrollbars
                                className="box round"
                                ref={this.resultScrollRef}
                                onScrollFrame={(e) => this.handleScrollFrame(e)}
                                autoHeight 
                                autoHide
                                autoHeightMin={'80vh'} 
                                autoHeightMax={'80vh'} 
                                thumbMinSize={30} 
                                universal={true}
                            >
                            <Container className="box round mb-2 p-4" fluid>
                            {this.props.product_count !== 0 &&
                                    this.props.products.length !== 0 &&
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className={this.props.page_active > 1 ? 'align-content-center' : "scroll-to-top-button-hide"}>
                                            <FiArrowLeft
                                                color="black"
                                                size={30}
                                                onClick={() => this.nextPage("prev")}
                                            />
                                        </div>
                                        <Pagination className="justify-content-center">
                                            {this.state.range > 1 &&
                                                <Fragment>
                                                    <Pagination.First onClick={() => this.PageRange('first')}/>
                                                    <Pagination.Prev onClick={() => this.PageRange('prev')}/>
                                                </Fragment>
                                            }  
                                                {this.state.pages}
                                            {this.state.range !== this.state.page_batch &&
                                                <Fragment>
                                                    <Pagination.Next onClick={() => this.PageRange('next')}/>
                                                    <Pagination.Last onClick={() => this.PageRange('last')}/>  
                                                </Fragment>
                                            }
                                        </Pagination>
                                        <div className={this.props.page_count != this.props.page_active ? 'align-content-center' : "scroll-to-top-button-hide"}>
                                            <FiArrowRight
                                                color="black"
                                                size={30}
                                                onClick={() => this.nextPage("next")}
                                            />
                                        </div>
                                    </div>
                                }
                                {this.props.product_count !== 0 && this.state.category !== 0 &&
                                    <Row>
                                        <Col lg={6} md={6} xs={6} className="d-flex align-items-center">
                                            <span>
                                                <Badge 
                                                    className="pointer py-2 px-2"
                                                    variant="info" 
                                                    onClick={() => this.resetHandler()}
                                                    >
                                                    Clear Results {this.props.product_count && `  (${this.props.product_count})`}
                                                </Badge> 
                                            </span>
                                            
                                        </Col>
                                        <Col xl={6} md={6} xs={6} className="d-flex justify-content-end">
                                            <span className="icon-box mx-2 p-2" onClick={() => this.changeView('grid')}>
                                                <FontAwesomeIcon icon={faTh} size="lg" className={this.state.mode === 'grid' ? 'rf-text-brw' : 'rf-text-gry'}/>
                                            </span>
                                            <span className="icon-box mx-2 p-2" onClick={() => this.changeView('list')}>
                                                <FontAwesomeIcon icon={faList} size="lg" className={this.state.mode === 'list' ? 'rf-text-brw' :  'rf-text-gry'}/>
                                            </span>
                                            {
                                                this.state.category !== 0 &&
                                                    <span className="icon-box mx-2 p-2" onClick={() => this.changeView('table')}>
                                                        <FontAwesomeIcon icon={faTable} size="lg" className={this.state.mode === 'table' ? 'rf-text-brw' :  'rf-text-gry'}/>
                                                    </span>
                                            }
                                        </Col>
                                    </Row>
                                }
                                <Row className="my-4 justify-content-center">
                                    {this.state.category !== 0 ?
                                        this.props.product_count !== 0 ?
                                            this.state.mode !== 'table' ?
                                                <CardDeck className="w-100">
                                                    {
                                                        this.props.products.length !== 0 ?
                                                            this.props.products.map((product, index) => (
                                                                this.state.mode === 'grid' ?
                                                                    <Col className="mb-4" lg={12} xl={4} key={index}>
                                                                        <Container className="d-flex flex-column justify-content-center align-items-center h-100 border p-3">
                                                                            <div className="d-flex align-items-center justify-content-center " style={{ textAlign: 'center', width: '300px', height: '300px' }}> 
                                                                                <Link to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                                                    <Image src={product.image_link ? product.image_link : noImagePreview} width="80%"/>
                                                                                </Link>
                                                                            </div>
                                                                            <section className="d-flex flex-column">
                                                                                <Link className="rf-text-blu align-self-center" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                                                    {product.product_partnumber}
                                                                                </Link>
                                                                                <br/>
                                                                                <small>{product.manufacturer_details.name} &#8594; <i>{product.category_details.name}</i></small>
                                                                                {product.specifications.map((specification, index) => (
                                                                                    specification.specification === 1  &&
                                                                                    <Fragment key={index}>
                                                                                        <small>
                                                                                            <b><i>{specification.specification_details.name}: </i></b>
                                                                                            {specification.value}
                                                                                        </small>
                                                                                    </Fragment>
                                                                                ))}
                                                                            </section>
                                                                        </Container>
                                                                    </Col>
                                                                : <Col md={12}>
                                                                    <Row className="d-flex justify-content-center mx-md-5 mx-4 my-3 p-4 border" key={index}>
                                                                        <Col md={4} className="d-flex justify-content-center my-2">
                                                                            <Link to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                                                <Image src={product.image_link ? product.image_link : noImagePreview} width="80%"/>
                                                                            </Link>
                                                                        </Col>
                                                                        <Col md={8} className="d-flex flex-column justify-content-center text-center my-2">
                                                                            <h5>
                                                                                <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                                                    {product.product_partnumber}
                                                                                </Link>
                                                                            </h5>
                                                                            <small>{product.manufacturer_details.name} &#8594; <i>{product.category_details.name}</i></small>
                                                                            <br/>
                                                                            {product.specifications.map((specification, index) => (
                                                                                specification.specification === 1  &&
                                                                                <Fragment key={index}>
                                                                                    <small>
                                                                                        <b><i>{specification.specification_details.name} </i></b>
                                                                                    </small>
                                                                                    <small>{specification.value}</small>
                                                                                </Fragment>
                                                                            ))}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            ))
                                                        : this.state.mode === 'grid' ? <GridLoad/> : <ListLoad/>
                                                    }
                                                </CardDeck>
                                            : <ProductsTable 
                                                productList={this.props.products} 
                                                category={this.state.category}
                                                // sort={this.sortHandler}
                                            />
                                            : <Container>
                                                <Row>
                                                    <Col className="my-auto mx-auto text-center">
                                                        <Image width='30%' src={noResult}/>      
                                                        <h4>Oops!</h4>
                                                        <p>No results found. Try changing the filters or search.</p>
                                                    </Col>
                                                </Row>
                                            </Container>  
                                        : <GetStarted/>
                                    }
                                </Row>
                                {this.props.product_count !== 0 &&
                                    this.props.products.length !== 0 &&
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className={this.props.page_active > 1 ? 'align-content-center' : "scroll-to-top-button-hide"}>
                                            <FiArrowLeft
                                                color="black"
                                                size={30}
                                                onClick={() => this.nextPage("prev")}
                                            />
                                        </div>
                                        <Pagination className="justify-content-center">
                                            {this.state.range > 1 &&
                                                <Fragment>
                                                    <Pagination.First onClick={() => this.PageRange('first')}/>
                                                    <Pagination.Prev onClick={() => this.PageRange('prev')}/>
                                                </Fragment>
                                            }  
                                                {this.state.pages}
                                            {this.state.range !== this.state.page_batch &&
                                                <Fragment>
                                                    <Pagination.Next onClick={() => this.PageRange('next')}/>
                                                    <Pagination.Last onClick={() => this.PageRange('last')}/>  
                                                </Fragment>
                                            }
                                        </Pagination>
                                        <div className={this.props.page_count != this.props.page_active ? 'align-content-center' : "scroll-to-top-button-hide"}>
                                            <FiArrowRight
                                                color="black"
                                                size={30}
                                                onClick={() => this.nextPage("next")}
                                            />
                                        </div>
                                    </div>
                                }
                            </Container>
                            </Scrollbars>
                        </Col>                   
                    </Row>
                </Container>
                <Footer/>
                {/* {
                    (this.props.product_count === 0 || this.props.products.length === 0) ?
                            <Container className='m-0 footer-fixed' fluid>
                                <Footer/>
                            </Container>
                        : <Footer/>
                } */}
            </Fragment>
        )
    }
}

Products.propTypes = {
    getProducts: PropTypes.func.isRequired,
    unsetProducts: PropTypes.func.isRequired,
    page_active: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
    products: state.product.products,
    product_count: state.product.product_count,
    page_count: state.product.page_count,
    page_active: state.product.page_active
})

export default withRouter(connect(mapStateToProps, { getProducts, unsetProducts })(Products))