import React, { Component } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'

class SingleProduct extends Component {

    constructor(props) {
        super(props)
            this.state= {

        }
    }

    render () {
        return (
            <Container className="mt-5 p-4">
                <Row>
                    <Col className="d-flex flex-column align-items-center" 
                        lg={6} 
                        md={12}
                    >
                        <ContentLoader height={280} width={465} >
                            <rect x="3" y="3" rx="10" ry="10" width="500" height="300" />
                        </ContentLoader>
                    </Col>
                    <Col className="d-flex flex-column" lg={6} md={12}>
                        <ContentLoader height={280} width={465} >
                            {/* <rect x="3" y="3" rx="10" ry="10" width="500" height="300" /> */}
                            <rect x="3" y="3" rx="0" ry="0" width="292" height="20" />
                            <rect x="3" y="30" rx="0" ry="0" width="239" height="20" />
                            <rect x="3" y="60" rx="0" ry="0" width="274" height="20" />
                            <rect x="3" y="90" rx="0" ry="0" width="292" height="20" />
                            <rect x="3" y="120" rx="0" ry="0" width="239" height="20" />
                            <rect x="3" y="150" rx="0" ry="0" width="274" height="20" />
                        </ContentLoader>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center mt-5">
                        <ContentLoader height={400} width={800} >
                            <rect x="3" y="3" rx="10" ry="10" width="800" height="400" />
                        </ContentLoader>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default SingleProduct