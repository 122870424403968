import React, { Component, Fragment } from 'react'
import {Col, Row } from 'react-bootstrap'
import { Facebook } from 'react-content-loader'

class ListLoad extends Component {
    
    loadAnimation() {
        let content = []

        for (let i = 0; i < 5; i++) {
            content.push(
                <Row className="w-100 mx-5 my-3 p-4 border">
                    <Col className="d-flex justify-content-center">
                        <Facebook/>
                    </Col>
                </Row>
            )
        }

        return content
    }

    render() {
        return (
            <Fragment>
                <Row>
                    {this.loadAnimation()}
                </Row>                                           
            </Fragment>
        )
    }
}

export default ListLoad