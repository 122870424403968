import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import './assets/styles/theme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios'

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_ENDPOINT : process.env.REACT_APP_PROD_ENDPOINT
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common['Authorization'] = `Token ${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_TOKEN : process.env.REACT_APP_PROD_TOKEN}`

/**
 * * FOR LOCAL DEVELOPMENT ONLY!!
 */
//  axios.defaults.baseURL = process.env.REACT_APP_DEV_ENDPOINT
//  axios.defaults.headers.post['Content-Type'] = 'application/json'
//  axios.defaults.headers.common['Authorization'] = `Token ${process.env.REACT_APP_DEV_TOKEN}`

/**
 * * FOR LOCAL DEVELOPMENT ONLY!!
 */
//  axios.defaults.baseURL = process.env.REACT_APP_LOCAL_ENDPOINT
//  axios.defaults.headers.post['Content-Type'] = 'application/json'
//  axios.defaults.headers.common['Authorization'] = `Token ${process.env.REACT_APP_LOCAL_TOKEN}`

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
