import React, { Component } from 'react'
import { Image, Modal } from 'react-bootstrap'

class ImageModal extends Component {

    constructor(props) {
        super(props)
            this.state= {

        }
    }
    render () {
        return (
            <Modal
                animation={false}
                size="lg"
                show={true}
                onHide={this.props.onHide}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center mb-5">
                    <Image src={this.props.path} width="80%"/>
                </Modal.Body>
            </Modal>
        )
    }
}
export default ImageModal