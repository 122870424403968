import React, { Component } from 'react'
import { Badge, Button, Col, Container, Form, Image, Jumbotron, Row  } from 'react-bootstrap'
import ImageModal from '../ImageModal'
import { frequency_units, distance_units} from '../../../constant'
import { FreeSpacePathLossHelper } from '../../../helpers/toolHelpers'


class FreeSpacePathLoss extends Component {

    constructor(props) {
        super(props)
        this.state= {
            frequency_unit: 'MHz',
            distance_unit: 'm',
            frequency: '',
            distance: '',
            pathLoss: null,
            imageModal: false,
            imagePath: null
        }
    }

    imageModalHandler(path) {
        this.setState((state) => ({ 
            imageModal: !state.imageModal,
            imagePath: path
        }))
    }

    convertHandler(event) {

        let converted

        if (this.state.frequency || event) {
            let frequency = event ? event.target.value : this.state.frequency
            const distance = this.state.distance
    
            this.setState({ frequency: frequency })
            
            converted = FreeSpacePathLossHelper(
                this.state.distance_unit,
                this.state.frequency_unit,
                frequency,
                distance

            )
            this.setState({ pathLoss: converted })
        }
    }

    render () {
        return (
            <>
                <Container>
                    <Jumbotron>
                        <h4 className="rf-text-blu">Free Space Path Loss</h4>
                        <Row className="mt-5">
                            <Col md={12} lg={12} xl={6}>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Distance</Form.Label>
                                        <Form.Control
                                            type="number" 
                                            placeholder="Enter Distance" 
                                            value={this.state.distance}
                                            onChange={(event) => this.setState({ distance: event.target.value }, () => this.convertHandler()) }
                                        />
                                        <div className="my-3">
                                            {
                                                distance_units.map((dist, index) => (
                                                    <Badge key={index} className="m-1 p-2 pointer"
                                                        variant={this.state.distance_unit === dist ? 'info' : 'secondary'}
                                                        onClick={ () => this.setState({ distance_unit: dist }, () => this.convertHandler()) }
                                                    >
                                                        {dist}
                                                    </Badge>
                                                ))
                                            }
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Frequency</Form.Label>
                                        <Form.Control
                                            type="number" 
                                            placeholder="Enter Frequency" 
                                            value={this.state.frequency }
                                            onChange={this.convertHandler.bind(this)}
                                        />
                                        <div className="my-3">
                                            {
                                                frequency_units.filter(freq => freq !== 'THz' && freq !== 'Hz').map((freq, index) => (
                                                    <Badge key={index} className="mx-1 p-2 pointer"
                                                        variant={this.state.frequency_unit === freq ? 'info' : 'secondary'}
                                                        onClick={ () => this.setState({ frequency_unit: freq }, () => this.convertHandler()) }
                                                    >
                                                        {freq}
                                                    </Badge>
                                                ))
                                            }
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mt-5">
                                        <Form.Label>Path Loss (dB)</Form.Label>
                                        <Form.Control 
                                            placeholder="Result"
                                            defaultValue={this.state.pathLoss || ""}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col className="d-flex justify-content-center mt-4" md={12} lg={12} xl={6}>
                                <Image 
                                    src={`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/fspl.png`} 
                                    className="rfbackdoor-tools-image" 
                                    onClick={ () => this.imageModalHandler(`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-static-imgs/tools-imgs/fspl.png`) }
                                />
                            </Col>
                        </Row>
                    </Jumbotron>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">FSPL Formula</h4>
                    <p className="m-3">
                        π is the constant (3.1415...)
                        <br/>
                        <i>d</i> is the propagation distance (typically between antennas)
                        <br/>
                        λ is the wavelength
                        <br/>
                        <i>f</i> is the frequency of the signal
                        <br/>
                        <i>c</i> is the speed of light constant (299,792,458 m/s)
                        <br/>
                    </p>
                    <h2>[( 4 * π) * <i>d</i> ) / λ]^2</h2>
                    <p className="my-5">or</p>
                    <h2>[( 4 * π * <i>d</i> * <i>f</i> ) / <i>c</i>]^2 </h2>
                    <Button 
                        className="mt-5" variant="rfbackdoor-round-outline" 
                        href={`${process.env.REACT_APP_S3_BUCKET}rfbackdoor-files/tools-files/Free-Space-Path-Loss-Reference-Chart.pdf`}
                        target="_blank">
                            Download Reference Chart
                    </Button>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">What is Free Space Path Loss?</h4>
                    <p className="mt-3">
                        Free space path loss (FSPL) is the amount of signal power loss that occurs between two antenna feed points as a radio wave travels through 
                        the air along a line-of-sight path.  It assumes there are no obstructions or other undesired effects in the path.
                    </p>
                    <p className="mt-3">
                        FSPL is most often presented in units of dB, but it can also be given as a unitless value, since it is the expression of a power ratio and all the units cancel out.  
                    </p>
                    <p className="mt-3">
                        The formula for FSPL assumes that the distance between the antennas is much larger than the wavelength.  This puts the receiver in the far field.  
                        As a general rule of thumb, the far field can be assumed to be 10 times the wavelength (although, depending on who you ask, you'd be safe to estimate it 
                        as low as 2 times the wavelength). 
                    </p>
                    <p className="mt-3">
                        The concept of Free Space Path Loss can be compared to the ripples on the water that die down as they get farther from where a rock 
                        has hit the surface.  Similarly, radio waves lose energy as they propagate farther from the antenna that is broadcasting them.  Free space path loss 
                        is a number that tells just how much the signal power is diminished for a given distance and frequency.  
                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">Why estimate Free Space Path Loss?</h4>
                    <p className="mt-3">
                        Path loss is good to know when determining if you are transmitting enough power for the distance you intend to cover with your signal.  It also helps to identify if 
                        your receiver is sensitive enough to detect the incoming signal. 
                    </p>
                    <p className="mt-3">
                        FSPL is a function of distance and frequency.  The longer the distance traveled, and the higher the 
                        transmitted frequency, the greater the amount of loss that will occur as the signal propagates. 
                    </p>
                    <p className="mt-3">
                        FSPL is used to <i>approximate</i> the actual loss, since it does not account 
                        for other sources of attenuation in the signal, such as moisture in the air, obstacles of various kinds, and reflections.  Loss through another medium (besides air) would need 
                        to account for the attenuating properties of that medium.

                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">How do antenna properties affect path loss?</h4>
                    <p className="mt-3">
                        The IEEE definition of FSPL is "The loss between two isotropic radiators in free space, expressed as a power ratio."  
                        Isotropic radiators have a gain of 0 dB, so there is no need to factor any feed losses into the equation.
                    </p>
                    <p className="mt-3">
                        To calculate the path loss between the feed points of two non-isotropic antennas, one would need to account for the gain 
                        and feed losses of each antenna by subtracting them from the FSPL.  In that case, Path Loss (PL) = FSPL - Gtx - Grx, where 
                        Gtx is the gain (and feed losses) of the transmit antenna, and Grx is the gain (and feed losses) of the receive antenna).
                    </p>
                    <p className="mt-3">
                        So, Free Space Path Loss assumes isotropic antennas, while Path Loss is adapted to work with any arbitrary antenna.
                    </p>
                </Container>
                <Container className="my-5">
                    <h4 className="rf-text-blu">Does Free Space Path Loss actually depend on frequency?</h4>
                    <p className="mt-3">
                        Power density <i>in space</i> has no dependence on wavelength. Only when we factor in the receiving element do we introduce any dependence.  
                        What really matters is the effective capture area of the receiver.
                    </p>
                    <p className="mt-3">
                        Since transmitted energy spreads out in space as it travels a farther distance, a larger antenna aperture can capture more of the 
                        energy, and will therefore reduce the total propagation loss. 
                    </p>
                    <p className="mt-3">
                        A smaller aperture will capture less energy and result in more signal loss.  The effective aperture of the receiving antenna is 
                        proportional to the wavelength (and subsequently, the frequency).  This is where wavelength fits into the FSPL equation.  
                        The FSPL equation is derived from the Friis transmission formula, which accounts for the apertures of the antennas.
                    </p>
                </Container>
                {
                    this.state.imageModal && 
                        <ImageModal 
                            title="Free Space Path Loss" 
                            path={this.state.imagePath}
                            onHide={() => this.imageModalHandler()}
                    />
                }
            </>
        )
    }
}
export default FreeSpacePathLoss
