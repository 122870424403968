import React, { Component } from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Navigation from '../Navigation'
import Footer from '../subcomponents/Footer'
import header from '../../assets/images/header.png'
import factory from '../../assets/images/factory.png'
import board from '../../assets/images/board.png'
import computer from '../../assets/images/computer.png'
import axios from 'axios'

class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            product_count: '10,000',
            manufacturer_count: 'different'
        }
    }

    getManufacturerActiveCount() {
        axios.get('/v1/api/manufacturer-active/count').then(response => {
            this.setState({
                manufacturer_count: response.data.count
            })
        })
    }

    getProductEstimateCount() {
        axios.get('v1/api/products/count').then(response => {
            const estimatedCount = parseInt(parseInt(response.data.count) / 10000) * 10000

            this.setState({
                product_count: estimatedCount.toLocaleString()
            })
        }) 
    }

    componentDidMount() {
        this.getManufacturerActiveCount()
        this.getProductEstimateCount()
    }

    render () {
        return (
            <Container className="no-padding" fluid>
                {/* start Header Component */}
                <Navigation/>
                {/* end of Header Component */}
                <Image width='100%' src={header}/>
                {/* start of homepage contenrs */}
                {/* <Container>
                    <Row className="justify-content-md-center mt-5">
                        <Col md="auto">
                            <h4 className="rf-text-brw">Search Products</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="my-auto mx-auto">
                            <Button href="/products" className="button-end round" variant="light" block>
                                <FontAwesomeIcon icon={faSearch}/>
                            </Button>
                        </Col>
                    </Row>
                </Container> */}
                <Container>
                    <Row className="my-5">
                        <Col md={6}>
                            <h3 className="mb-5 rf-text-brw">RF Parts Database</h3>
                            <Image width='80%' src={board}/>
                        </Col>
                        <Col md={6} className="my-auto mx-auto">
                            <p>
                                {
                                    `Browse our online, searchable catalog of RF 
                                    parts from throughout the RF industry. We have compiled a database of 
                                    over ${this.state.product_count} components from ${this.state.manufacturer_count} manufacturers to help you find what you need faster, 
                                    saving you time and money. We are committed to providing you with the most accurate, 
                                    most complete, RF parts search tool on the web.`
                                }
                            </p>
                            <Button href="/products" variant="rfbackdoor-round">See All Products</Button>
                        </Col>
                    </Row>
                    <Row className="my-5 rfbackdoor-mobile-hide">
                        <Col md={6} className="my-auto mx-auto">
                            <p>
                                Looking for contact information for a particular RF manufacturer? 
                                Wondering who might make that component you are looking for? 
                                Learn more about RF manufacturers by browsing our filterable 
                                collection of 102 companies.
                            </p>
                            <Button href="/manufacturers" variant="rfbackdoor-round">See All Manufacturers</Button>
                        </Col>
                        <Col md={6}>
                            <h3 className="mb-5 rf-text-brw">Manufacturers</h3>
                            <Image width='80%' src={factory}/>
                        </Col>
                    </Row>
                    <Row className="my-5 rfbackdoor-desktop-hide">
                        <Col md={6} className="my-auto mx-auto">
                            <h3 className="mb-5 rf-text-brw">Manufacturers</h3>
                            <Image width='80%' src={factory}/>
                        </Col>
                        <Col md={6}>
                            <p>
                                Looking for contact information for a particular RF manufacturer? 
                                Wondering who might make that component you are looking for? 
                                Learn more about RF manufacturers by browsing our filterable 
                                collection of 102 companies.
                            </p>
                            <Button href="/manufacturers" variant="rfbackdoor-round">See All Manufacturers</Button>
                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col md={6}>
                            <h3 className="mb-5 rf-text-brw">Engineering Resources</h3>
                            <Image width='80%' src={computer}/>
                        </Col>
                        <Col md={6} className="my-auto mx-auto">
                            <p>
                                Having the right tool for the job always makes things easier. 
                                We have built some handy tools and calculators to do just that. 
                                How convenient, right?
                            </p>
                            <Button href="/tools" variant="rfbackdoor-round">See All Tools and Calculators</Button>
                        </Col>
                    </Row>
                    {/* <Container>
                        <Row>
                            <Col>
                                <h4 className="mb-5 rf-text-blu">Featured Products</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1">
                                <Card>
                                    <Card.Img variant="top" src={factory}/>
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                        </Card.Text>
                                        <Button variant="primary">See more</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="px-1">
                                <Card>
                                    <Card.Img variant="top" src={factory}/>
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                        </Card.Text>
                                        <Button variant="primary">See more</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="px-1">
                                <Card>
                                    <Card.Img variant="top" src={factory}/>
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                        </Card.Text>
                                        <Button variant="primary">See more</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="px-1"> 
                                <Card>
                                    <Card.Img variant="top" src={factory}/>
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                        </Card.Text>
                                        <Button variant="primary">See more</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="px-1">
                                <Card>
                                    <Card.Img variant="top" src={factory}/>
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                        </Card.Text>
                                        <Button variant="primary">See more</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container> */}


                </Container>

                <Footer/>
                {/* end of  homepage contents*/}

            </Container>
        )
    }
}

export default Home