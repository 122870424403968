import React, { Component } from 'react'
import { withRouter } from "react-router"
import { Container, Image } from 'react-bootstrap'
import Navigation from '../Navigation'
import Footer from '../subcomponents/Footer'
import FrequencyToWavelength from '../subcomponents/ToolsComponents/FrequencyToWavelength'
import FrequencyConversion from '../subcomponents/ToolsComponents/FrequencyConversion'
import FreeSpacePathLoss from '../subcomponents/ToolsComponents/FreeSpacePathLoss'
import VoltageStandingWaveRatio from '../subcomponents/ToolsComponents/VoltageStandingWaveRatio'



class SpecificTools extends Component {

    constructor(props) {
        super(props)
            this.state= {

        }
    }

    render () {
        const { match } = this.props
        const tool = typeof match.params.title ? match.params.title : 'default'

        return (
            <>
                <Navigation/>
                {
                    tool === 'frequency-to-wavelength' ?
                        <FrequencyToWavelength/>
                    : tool === 'frequency-conversion' ?
                        <FrequencyConversion/>
                    : tool === 'free-space-path-loss' ?
                        <FreeSpacePathLoss/>
                    : tool === 'voltage-standing-wave-ratio' ? 
                        <VoltageStandingWaveRatio/>
                    : <Container
                        className="d-flex flex-column align-items-center"
                    >
                        <Image
                            width='30%'
                            src={require('../../assets/images/no-result.png').default}
                        />
                        <p>Sorry! It seems that the tools is not found or unavailable!</p>
                    </Container>
                }
                <Footer/>
            </>
        )
    }
}
export default withRouter(SpecificTools)