import React, { Component } from 'react'
import { 
    Table 
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import TableLoad from './LoadingAnimation/TableLoad'
import { HiSortAscending, HiSortDescending } from "react-icons/hi"
import { configureProductNumberForLink } from '../../helpers/productHelpers'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch } from '@fortawesome/free-solid-svg-icons'

class ProductsTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            product_list: [],
            spec_being_sort: null,
            sort_active: null
        }
    }

    TableDataFilter(specs, id) {
        let i = specs.filter(specification => specification.specification === id)

        if (i.length > 0) {
            return i[0].value
        } else {
            return '--'
        }
    }

    sortHandler(specs, sort) {
        let product_sort = []

        this.setState({
            spec_being_sort: specs,
            sort_active: sort
        })

        if (specs === 'partnumber') {
            if (sort === 'asc') {
                product_sort = this.state.product_list.sort((a, b) => a.product_partnumber.localeCompare(b.product_partnumber))
                this.setState({ product_list: product_sort })
            } else {
                product_sort = this.state.product_list.sort((a, b) => b.product_partnumber.localeCompare(a.product_partnumber))
                this.setState({ product_list: product_sort })
            }
        } else {
            let product_specs_filter = []
            let final_product_list = []
            let is_alphanumeric = false
            const regExp = /[a-zA-Z]/g

            for (let x = 0; x < this.state.product_list.length; x++) {
                const data = {
                    partnumber: this.state.product_list[x].product_partnumber,
                    specs: this.state.product_list[x].specifications
                                .filter(specification => specification.specification === specs)
                                .map(({ value }) => value)[0]
                            ? this.state.product_list[x].specifications.filter(specification => specification.specification === specs).map(({ value }) => value)[0]
                            : ''
                }

                if (regExp.test(data.specs)) {
                    is_alphanumeric = true
                }

                product_specs_filter.push(data)
            }

            if (sort === 'asc') {
                if (is_alphanumeric) {
                    product_sort = product_specs_filter.sort((a, b) => a.specs.localeCompare(b.specs))
                } else {
                    product_sort = product_specs_filter.sort((a, b) => a.specs - b.specs)
                }
            } else {
                if (is_alphanumeric) {
                    product_sort = product_specs_filter.sort((a, b) => b.specs.localeCompare(a.specs))
                } else {
                    product_sort = product_specs_filter.sort((a, b) => b.specs - a.specs)
                }
            }

            for (let y = 0; y < product_sort.length; y++) {
                const part_number = product_sort[y].partnumber
                const temp_data = this.state.product_list.filter(product => product.product_partnumber === part_number)[0]

                final_product_list.push(temp_data)
            }

            this.setState({
                product_list: final_product_list
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.productList !== this.props.productList) {
            this.setState({
                product_list: this.props.productList
            })
        }
    }

    componentDidMount() {
        this.setState({
            product_list: this.props.productList
        })
    }

    render() {
        return(
            <React.Fragment>
                <Table responsive>
                    {this.props.category === 1 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        VSWR Max
                                        {
                                            this.state.spec_being_sort === 10 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(10, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(10, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min.(MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max.(MHz) 
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Conn. 1 Type
                                        {
                                            this.state.spec_being_sort === 11 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(11, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(11, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Conn. 2 Type
                                        {
                                            this.state.spec_being_sort === 12 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(12, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(12, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td width="200px">
                                                    <Link 
                                                        className="rf-text-blu" 
                                                        to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}
                                                    >
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td width="200px">
                                                    {this.TableDataFilter(product.specifications, 10)}
                                                </td>
                                                <td width="200px">
                                                    {this.TableDataFilter(product.specifications, 2)}
                                                </td>
                                                <td width="200px">
                                                    {this.TableDataFilter(product.specifications, 3)}
                                                </td>
                                                <td width="200px">
                                                    {this.TableDataFilter(product.specifications, 11)}
                                                </td>
                                                <td width="200px">
                                                    {this.TableDataFilter(product.specifications, 12)}
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/>
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {this.props.category === 2 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Gain Max (dB)
                                        {
                                            this.state.spec_being_sort === 23 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(23, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(23, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Psat (dBm)
                                        {
                                            this.state.spec_being_sort === 21 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(21, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(21, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min.(MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max.(MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 23)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 21)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/>
                                }
                            </tbody> 
                        </React.Fragment>
                    }
                    
                    {this.props.category === 3 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Gain (dB)
                                        {
                                            this.state.spec_being_sort === 23 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(23, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(23, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        VSWR
                                        {
                                            this.state.spec_being_sort === 10 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(10, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(10, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min.(MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max.(MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 23)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 10)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/>
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {this.props.category === 4 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min.(MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max.(MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Attenuation(dB)
                                        {
                                            this.state.spec_being_sort === 44 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(44, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(44, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Power(dBm)
                                        {
                                            this.state.spec_being_sort === 248 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(248, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(248, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 44)}</td>
                                                {/* {not sure about 17, wait for confirmation} */}
                                                <td>{this.TableDataFilter(product.specifications, 248)}</td> 
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/>
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {(this.props.category === 5 || this.props.category === 8 || this.props.category === 9 
                        || this.props.category === 14 || this.props.category === 17 || this.props.category === 21 
                        || this.props.category === 22 || this.props.category === 24 || this.props.category === 26
                        || this.props.category === 27 || this.props.category === 28 || this.props.category === 29
                        || this.props.category === 30 || this.props.category === 31 || this.props.category === 32
                        || this.props.category === 33 || this.props.category === 34 || this.props.category === 35
                        || this.props.category === 36 || this.props.category === 37 || this.props.category === 38
                        || this.props.category === 40 || this.props.category === 41) &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    {this.props.category === 40 ?
                                        <React.Fragment>
                                            <th>
                                                Input Voltage Min.
                                                {
                                                    this.state.spec_being_sort === 27 &&
                                                        this.state.sort_active === 'dsc' ?
                                                            <HiSortDescending
                                                                size={20}
                                                                color='#d3d3d3'
                                                                className="mx-1 pointer"
                                                                onClick={() => this.sortHandler(27, 'asc')}
                                                            />
                                                        : <HiSortAscending
                                                            size={20}
                                                            color='#d3d3d3'
                                                            className="mx-1 pointer"
                                                            onClick={() => this.sortHandler(27, 'dsc')}
                                                        />
                                                }
                                            </th>
                                            <th>
                                                Input Voltage Max.
                                                {
                                                    this.state.spec_being_sort === 28 &&
                                                        this.state.sort_active === 'dsc' ?
                                                            <HiSortDescending
                                                                size={20}
                                                                color='#d3d3d3'
                                                                className="mx-1 pointer"
                                                                onClick={() => this.sortHandler(28, 'asc')}
                                                            />
                                                        : <HiSortAscending
                                                            size={20}
                                                            color='#d3d3d3'
                                                            className="mx-1 pointer"
                                                            onClick={() => this.sortHandler(28, 'dsc')}
                                                        />
                                                }
                                            </th>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            <th>
                                                Freq. Min.(MHz)
                                                {
                                                    this.state.spec_being_sort === 2 &&
                                                        this.state.sort_active === 'dsc' ?
                                                            <HiSortDescending
                                                                size={20}
                                                                color='#d3d3d3'
                                                                className="mx-1 pointer"
                                                                onClick={() => this.sortHandler(2, 'asc')}
                                                            />
                                                        : <HiSortAscending
                                                            size={20}
                                                            color='#d3d3d3'
                                                            className="mx-1 pointer"
                                                            onClick={() => this.sortHandler(2, 'dsc')}
                                                        />
                                                }
                                            </th>
                                            <th>
                                                Freq. Max.(MHz)
                                                {
                                                    this.state.spec_being_sort === 3 &&
                                                        this.state.sort_active === 'dsc' ?
                                                            <HiSortDescending
                                                                size={20}
                                                                color='#d3d3d3'
                                                                className="mx-1 pointer"
                                                                onClick={() => this.sortHandler(3, 'asc')}
                                                            />
                                                        : <HiSortAscending
                                                            size={20}
                                                            color='#d3d3d3'
                                                            className="mx-1 pointer"
                                                            onClick={() => this.sortHandler(3, 'dsc')}
                                                        />
                                                }
                                            </th>
                                            <th>Description</th>
                                        </React.Fragment>
                                    }
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                {this.props.category === 40 ?
                                                    <React.Fragment>
                                                        {/* not sure about input voltage is the same with voltage wait for confirmation */}
                                                        <td>{this.TableDataFilter(product.specifications, 27)}</td>
                                                        <td>{this.TableDataFilter(product.specifications, 28)}</td>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                        <td>{this.TableDataFilter(product.specifications, 3)}</td> 
                                                    </React.Fragment>
                                                }
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/>
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {this.props.category === 6 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min.(MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max.(MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td> 
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/>
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {this.props.category === 7 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min.(MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max.(MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Insertion Loss Min.(dB)
                                        {
                                            this.state.spec_being_sort === 15 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(15, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(15, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Insertion Loss Max.(dB)
                                        {
                                            this.state.spec_being_sort === 16 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(16, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(16, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Conn. 1 Type
                                        {
                                            this.state.spec_being_sort === 11 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(11, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(11, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Conn. 2 Type
                                        {
                                            this.state.spec_being_sort === 12 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(12, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(12, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 15)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 16)}</td> 
                                                <td>{this.TableDataFilter(product.specifications, 11)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 12)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        
                                        ))
                                    : <TableLoad/>            
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {(this.props.category === 10 || this.props.category === 11) &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min.(MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max.(MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Insertion Loss Min.(dB)
                                        {
                                            this.state.spec_being_sort === 15 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(15, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(15, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Insertion Loss Max.(dB)
                                        {
                                            this.state.spec_being_sort === 16 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(16, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(16, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Power (dBm)
                                        {
                                            this.state.spec_being_sort === 248 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(248, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(248, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Isolation (dB)
                                        {
                                            this.state.spec_being_sort === 60 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(60, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(60, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 15)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 16)}</td> 
                                                <td>{this.TableDataFilter(product.specifications, 248)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 60)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/>    
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {this.props.category === 12 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Data Rate In (Gbps)
                                        {
                                            this.state.spec_being_sort === 79 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(79, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(79, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Data Rate Out (Gbps)
                                        {
                                            this.state.spec_being_sort === 79 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(79, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(79, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ? 
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td >
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 79)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 79)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/> 
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {(this.props.category === 13 || this.props.category === 20) &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min.(MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max.(MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Insertion Loss (dB)
                                        {
                                            this.state.spec_being_sort === 15 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(15, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(15, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        VSWR
                                        {
                                            this.state.spec_being_sort === 10 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(10, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(10, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ? 
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 15)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 10)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/> 
                                }
                            </tbody> 
                        </React.Fragment>
                    }
                    
                    {this.props.category === 15 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Power (dBm)
                                        {
                                            this.state.spec_being_sort === 248 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(248, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(248, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 4 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(4, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(4, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 5 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(5, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(5, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Insertion Loss (dB)
                                        {
                                            this.state.spec_being_sort === 15 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(15, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(15, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Ch 2 Insertion Loss (dB)
                                        {
                                            this.state.spec_being_sort === 17 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(17, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(17, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ? 
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 248)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 4)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 5)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 15)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 17)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/> 
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {this.props.category === 16 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Power (dBm)
                                        {
                                            this.state.spec_being_sort === 248 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(248, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(248, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 2 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(2, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(2, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 3 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(3, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(3, 'dsc')}
                                                />
                                        }
                                    </th>
                                    {/* <th>
                                        Filter Type
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th> */}
                                    <th>
                                        Insertion Loss
                                        {
                                            this.state.spec_being_sort === 15 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(15, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(15, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 248)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 2)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 3)}</td>
                                                {/* Filter Type not found, subject to change IMPORTANT!!!! */}
                                                {/* <td>{this.TableDataFilter(product.specifications, 3)}</td> */}
                                                <td>{this.TableDataFilter(product.specifications, 15)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        
                                        ))
                                    : <TableLoad/> 
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {(this.props.category === 18 || this.props.category === 25) &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Input Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 121 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(121, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(121, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Input Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 122 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(122, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(122, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Output Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 123 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(123, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(123, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Output Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 124 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(124, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(124, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 121)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 122)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 123)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 124)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/> 
                                }
                            </tbody> 
                        </React.Fragment>
                    }

                    {this.props.category === 19 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Substrate Diameter (in)
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        Thickness Variation
                                        {
                                            this.state.spec_being_sort === 139 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(139, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(139, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 17)}</td>
                                                {/* Substrate Diameter not found, max thickness not sure subject to change IMPORTANT!!!! */}
                                                {/* <td>{this.TableDataFilter(product.specifications, 99)}</td> */}
                                                <td>{this.TableDataFilter(product.specifications, 139)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/> 
                                }
                            </tbody> 
                        </React.Fragment>
                    } 

                    {this.props.category === 23 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        RF Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 143 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(143, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(143, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        RF Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 144 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(144, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(144, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        LO Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 145 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(145, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(145, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        LO Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 146 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(146, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(146, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        IF Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 147 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(147, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(147, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        IF Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 148 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(148, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(148, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 143)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 144)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 145)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 146)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 147)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 148)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/> 
                                }
                            </tbody> 
                        </React.Fragment>
                    }  

                    {this.props.category === 39 &&
                        <React.Fragment>
                            <thead>
                                <tr>
                                    <th>
                                        Part Number
                                        {
                                            this.state.spec_being_sort === 'partnumber' &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler('partnumber', 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler('partnumber', 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        RF Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort ===  143 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(143, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(143, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        RF Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 144 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(144, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(144, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        TX Freq. Min. (MHz)
                                        {
                                            this.state.spec_being_sort === 229 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(229, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(229, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>
                                        TX Freq. Max. (MHz)
                                        {
                                            this.state.spec_being_sort === 230 &&
                                                this.state.sort_active === 'dsc' ?
                                                    <HiSortDescending
                                                        size={20}
                                                        color='#d3d3d3'
                                                        className="mx-1 pointer"
                                                        onClick={() => this.sortHandler(230, 'asc')}
                                                    />
                                                : <HiSortAscending
                                                    size={20}
                                                    color='#d3d3d3'
                                                    className="mx-1 pointer"
                                                    onClick={() => this.sortHandler(230, 'dsc')}
                                                />
                                        }
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>                
                            <tbody>
                                {
                                    this.state.product_list.length !== 0 ?
                                        this.state.product_list.map((product, index) => (
                                            <tr  key={index}>
                                                <td>
                                                    <Link className="rf-text-blu" to={{ pathname: `/product/${product.id}/${configureProductNumberForLink(product.product_partnumber)}` }}>
                                                        {product.product_partnumber}
                                                    </Link>
                                                </td>
                                                <td>{this.TableDataFilter(product.specifications, 143)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 144)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 229)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 230)}</td>
                                                <td>{this.TableDataFilter(product.specifications, 1)}</td>
                                            </tr>
                                        ))
                                    : <TableLoad/> 
                                }
                            </tbody> 
                        </React.Fragment>
                    }  
                </Table>
            </React.Fragment>
        )
    }

}

export default ProductsTable