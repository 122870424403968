import React, { Component, Fragment } from 'react'
import { 
    Alert,
    Button, 
    Card, 
    Container, 
    Col, 
    Form, 
    FormControl, 
    Image, 
    InputGroup, 
    Modal,
    Table,
    Row 
} from 'react-bootstrap'
import { connect } from 'react-redux'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import Custom from '../subcomponents/LoadingAnimation/Custom'
import Navigation from '../Navigation'
import Footer from '../subcomponents/Footer'
import { FiArrowUp } from "react-icons/fi"
import noResult from '../../assets/images/no-result.png'

class Manufacturers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            category_list: [],
            active_manufacturer: null,
            manufacturers: [],
            manufacturer_details: [],
            searchManuf: '',
            product_count: [],
            show: false,
            categories: [],
            filterByCategoryValue: 0
        }
    }

    handleInputChange(propertyName, event) {
        event.preventDefault()

        const data = this.state
        data[propertyName] = event.target.value
        this.setState(data)
    }

    filterByCategory(event) {
        this.setState({ 
            manufacturers: [], 
            searchManuf: '',
            filterByCategoryValue: event.target.value
        })
        
        const categ_id = event.target.value
        let manufacturers = []
        let manufacturers_sort = []

        if (categ_id == 0) {
            this.getManufacturers()
        } else {
            axios.get(`v1/api/manufacturers/?category=${categ_id}`).then(response => {
                // this.setState({ manufacturers: response.data })
                manufacturers = response.data
                manufacturers_sort = manufacturers.sort((a, b) => a.name.localeCompare(b.name))

                if (response.data.length === 0) {
                    this.setState({ manuf_no_results: true })
                } else {
                    this.setState({ manuf_no_results: false })
                }

                this.setState({ 
                    manufacturers: Array.from(new Set(manufacturers_sort))
                })
                    
            })
        }
    }

    filterManufacturers(id) {
        let categories = []
        let list = {}

        this.setState({ 
            manufacturer: id, 
            manufacturer_details: [],
            product_count: [] 
        })

        axios.get(`v1/api/manufacturers/?id=${id}`).then(response => {
            this.setState({ manufacturer_details: response.data })
        })

        this.state.category_list.map(category => (
            axios.get(`v1/api/products/?manufacturer__in=${id}&category=${category.id}`).then(response => {
                if (response.data.count !== 0) {
                    list = {
                        manufacturer: id,
                        category: category.id,
                        category_name: category.name,
                        product_count: response.data.count
                    }
                    categories.push(list)
                    this.setState({ product_count: categories })
                }
            })
        ))
        localStorage.setItem('s_manuf', id)
    }

    getAllCategory() {
        let categories = []

        axios.get(`v1/api/category/`).then(response => {
            categories = [...categories, ...response.data]
            let categories_sort = categories.sort((a, b) => a.name.localeCompare(b.name))
            this.setState({ 
                category_list: categories_sort
            })
        })
    }

    getManufacturers(event) {
        if (event) {
            event.preventDefault()
        }
       
        let search
        let manufacturers = []
        let manufacturers_sort = []

        search = this.state.searchManuf !== '' ? this.state.searchManuf : ''

        axios.get(`v1/api/manufacturers/?search=${search}`).then(response => {
            manufacturers = response.data
            manufacturers_sort = manufacturers.sort((a, b) => a.name.localeCompare(b.name))

            if (response.data.length === 0) {
                this.setState({ manuf_no_results : true })
            } else {
                this.setState({ manuf_no_results: false })
            }

            this.setState({ 
                manufacturers: Array.from(new Set(manufacturers_sort)),
                filterByCategoryValue: 0
            })
        }).catch(function(error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
            }
            console.log(error.config)
        })
    }

    showManufacturerDeets(manufacturer) {
        axios.get(`v1/api/category-available/?manufacturer=${manufacturer.id}`).then(response => {
            this.setState({
                show: true,
                manufacturer: manufacturer,
                categories: response.data
            })
        })
        // this.setState({
        //     show: true,
        //     manufacturer: manufacturer
        // })
    }

    componentDidMount() {
        this.getAllCategory()
        this.getManufacturers()
        Number(localStorage.getItem('s_manuf')) && this.filterManufacturers(Number(localStorage.getItem('s_manuf')))
    }

    render() {
        return (
            <Fragment>
                <Navigation/>
                <div className="scroll-to-top-button" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                    <FiArrowUp
                        color="white"
                        size={30}
                    />
                </div>
                <Container className="my-sm-2 px-sm-3 my-md-5 px-md-5">
                    <Row className="my-4 justify-content-center">
                        <Col lg={6} md={12}>
                            <Form
                                onSubmit={this.getManufacturers.bind(this)}
                            >
                                <Form.Group controlId="exampleForm.SelectCustom">
                                    <Form.Label>
                                        <small>
                                            <i>Search for available manufacturer</i>
                                        </small>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="basic-addon2"
                                            onChange={this.handleInputChange.bind(this, 'searchManuf')}
                                            value={this.state.searchManuf}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text 
                                                className="pointer"
                                                id="basic-addon2" 
                                                onClick={() => this.getManufacturers()}
                                            >
                                                <FontAwesomeIcon icon={faSearch} size="sm"/>
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col lg={6} md={12}>
                            <Form>
                                <Form.Group controlId="exampleForm.SelectCustom">
                                    <Form.Label>
                                        <small>
                                            <i>Filter manufacturers by the availability of products per category</i>
                                        </small>
                                    </Form.Label>
                                    <Form.Control 
                                        as="select" 
                                        value={this.state.filterByCategoryValue}
                                        onChange={this.filterByCategory.bind(this)} custom
                                    >
                                        <option value="0">All</option>
                                        {
                                            this.state.category_list.map((category, index) => (
                                                <option key={index} value={category.id}>{category.name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                {
                    this.state.manufacturers && this.state.manufacturers.length !== 0 &&
                        <Container className="my-sm-2 px-sm-3 my-md-3 px-md-5">
                            <Alert variant="success">
                                <strong> Results: </strong> {this.state.manufacturers.length} Manufacturer/s
                            </Alert>
                        </Container>
                }
                <Container className="my-sm-2 px-sm-3 my-md-3 px-md-5" fluid>
                    <Row className="my-4 justify-content-center">
                        {
                            this.state.manufacturers && this.state.manufacturers.length !== 0 ?
                                this.state.manufacturers.map((manufacturer, idx) => (
                                    <Fragment>
                                        <Card 
                                            key={idx}
                                            style={{ 
                                                width: '24rem',
                                                margin: 5
                                            }}
                                        >
                                            {
                                                manufacturer.deets.map((deet, idx) => (
                                                    <Fragment
                                                        key={idx}
                                                    >
                                                        <div 
                                                            className="d-flex align-items-center align-self-center justify-content-center" 
                                                            style={{ 
                                                                width: '300px', 
                                                                height: '300px' ,
                                                                textAlign: 'center'
                                                            }}
                                                        > 
                                                            <Card.Img
                                                                style={{
                                                                    maxWidth: '250px'
                                                                }} 
                                                                variant="top" 
                                                                src={deet.logo_link} 
                                                            />
                                                        </div>
                                                        <Card.Body>
                                                            <Card.Title>
                                                                {manufacturer.name}
                                                            </Card.Title>
                                                            <Card.Text
                                                                className="ellipsis"
                                                            >
                                                                {deet.description}
                                                            </Card.Text>
                                                            <Button 
                                                                variant="rfbackdoor"
                                                                onClick={() => this.showManufacturerDeets(manufacturer)}
                                                            >
                                                                More 
                                                                <i className="bi bi-chevron-double-right"></i>
                                                            </Button>
                                                        </Card.Body>
                                                    </Fragment>
                                                ))
                                            }
                                        </Card>
                                    </Fragment>
                                ))
                            : this.state.manuf_no_results ?
                                <Container>
                                    <Row>
                                        <Col className="my-auto mx-auto text-center">
                                            <Image width='30%' src={noResult}/>      
                                            <h4>Oops!</h4>
                                            <p>No results found. Try changing the filters or search.</p>
                                        </Col>
                                    </Row>
                                </Container>  
                            : <Container>
                                <Custom/>
                            </Container>
                        }
                    </Row>
                </Container>
            <Footer/>
            <Modal
                show={this.state.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => this.setState({ show: false })}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.manufacturer && this.state.manufacturer.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        { 
                            this.state.manufacturer && this.state.manufacturer.deets && 
                                this.state.manufacturer.deets.map((deet, idx) => (
                                    <Fragment
                                        key={idx}
                                    >
                                        <div 
                                            className="d-flex align-items-center align-self-center justify-content-center"
                                        > 
                                            <Image src={deet.logo_link} width="40%"/>
                                        </div>
                                        <p className="my-5">
                                            <small>
                                                Address
                                            </small>
                                            <br/>
                                            <i>{deet.address}</i>
                                        </p>
                                        <p className="my-5">
                                            {deet.description}
                                        </p>
                                        <p>
                                            <FontAwesomeIcon className="mr-2"  icon={faEnvelope} size="sm"/>
                                            <small><i>Email</i></small>
                                            <br/>
                                            {deet.email ? deet.email : 'Not Available'}
                                        </p>
                                        <p className="mb-3">
                                            <FontAwesomeIcon className="mr-2"  icon={faPhone} size="sm"/>
                                            <small><i>Contact Information</i></small>
                                            <br/>
                                            {
                                                this.state.manufacturer.contact.map((contact, index) => (
                                                    <Fragment key={index}>
                                                        {contact.contact}
                                                    </Fragment>
                                                ))
                                            }
                                        </p>
                                        {
                                            this.state.categories && this.state.categories.length > 0 ?
                                            <div className='mt-3'>
                                                <b>Available Products</b>
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Categories</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.categories.map((categories, idx) => (
                                                                <tr>
                                                                    <td>{idx + 1}</td>
                                                                    <td>{categories.category_details.name}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : <b className='mt=3'><i>No available products at the moment.</i></b>
                                        }
                                    </Fragment>
                                ))
                        }
                </Modal.Body>
                <Modal.Footer>
                    <a
                        href={this.state.manufacturer && this.state.manufacturer.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button
                            variant="rfbackdoor"
                        >
                            Visit Site
                        </Button>
                    </a>
                </Modal.Footer>
            </Modal> 
        </Fragment>
        )
    }
}

export default connect()(Manufacturers)