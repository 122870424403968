/**
 * This file containes all functions for calculating or converting wavelengths, frequencies and etc.
 * This functions are used on tools page.
 */
export function FrequencyToWavelengthHelper(frequency, frequency_unit, wavelength_unit) {
    const C = 299792458
    const FTPERM = 3.280839895
    let frequencyMultiplier = 1
    let wavelengthMultiplier = 1
    let wavelength

    switch (frequency_unit) {
        case 'kHz':
            frequencyMultiplier = 1e3
            break
        case 'MHz':
            frequencyMultiplier = 1e6
            break
        case 'GHz': 
            frequencyMultiplier = 1e9
            break
        default:
            break
    }

    switch (wavelength_unit) {
        case 'cm':
            wavelengthMultiplier = 100
            break
        case 'mm':
            wavelengthMultiplier = 1000
            break
        case 'ft':
            wavelengthMultiplier = FTPERM
            break
        case 'in':
            wavelengthMultiplier = 12*FTPERM
            break
        default:
            break
    }

    wavelength = ((C/(frequency*frequencyMultiplier))*wavelengthMultiplier).toPrecision(3)

    return wavelength
}

export function FrequencyConversionHelper(frequency, f_primary, f_secondary) {
    if (f_primary === 'Hz') {
        switch(f_secondary) {
            case 'kHz':
                return frequency / 1000
            case 'MHz':
                return frequency / 1e+6
            case 'GHz':
                return frequency / 1e+9
            case 'THz':
                return frequency / 1e+12
            default: return frequency
        }
    } else if (f_primary === 'kHz') {
        switch(f_secondary) {
            case 'Hz':
                return frequency * 1000
            case 'MHz':
                return frequency / 1000
            case 'GHz':
                return frequency / 1e+6
            case 'THz':
                return frequency / 1e+9
            default: return frequency
        }
    } else if (f_primary === 'MHz') {
        switch(f_secondary) {
            case 'Hz':
                return frequency * 1e+6
            case 'kHz':
                return frequency * 1000
            case 'GHz':
                return frequency / 1000
            case 'THz':
                return frequency / 1e+6
            default: return frequency
        }
    } else if (f_primary === 'GHz') {
        switch(f_secondary) {
            case 'Hz':
                return frequency * 1e+9
            case 'kHz':
                return frequency * 1e+6
            case 'MHz':
                return frequency * 1000
            case 'THz':
                return frequency / 1000
            default: return frequency
        }
    } else if (f_primary === 'THz') {
        switch(f_secondary) {
            case 'Hz':
                return frequency * 1e+12
            case 'kHz':
                return frequency * 1e+9
            case 'MHz':
                return frequency * 1e+6
            case 'GHz':
                return frequency * 1000
            default: return frequency
        }
    }
}

export function FreeSpacePathLossHelper(distance_unit, frequency_unit, frequency, distance) {
    // [(4*pi*d*f)/c]^2 --> formula to be used.
    const pi = Math.PI
    // m/s needs to convert depending on the distant unit selected --> speed of light
    let c = 2.998e+8 
    
    switch(distance_unit) {
        case 'cm':
            // centimeter per second
            c = 2.998e+10
            break
        case 'm':
            // default
            break
        case 'km':
            // kilometer per second
            c = 299792
            break
        case 'in':
            // inches per second
            c = 1.18e+10
            break
        case 'ft':
            // feet per second
            c = 9.836e+8
            break
        case 'mi':
            // miles per second
            c = 186282
            break
        default:
            break
    }

    switch(frequency_unit) {
        case 'kHz':
            frequency = frequency * 1e3
            break
        case 'MHz':
            frequency = frequency * 1e6
            break
        case 'GHz':
            frequency = frequency * 1e9
            break
        default:
            break
    }
    // [(4*pi*d*f)/c]^2 --> formula to be used.
    const res = Math.pow([(4*pi*distance*frequency)/c], 2)
    const db = 10 * Math.log10(res)
    return db
}

export function VoltageStandingWaveRatioHelper(spec, value) {
    let vswr, rl, re

    switch(spec) {
        case 'vswr':
            vswr = Number(value)
            rl = (-20 * Math.log10((vswr - 1) / (vswr + 1))).toFixed(1)
            re = ((vswr - 1) / (vswr + 1)).toFixed(5)
            break
        case 'RL':
            rl = Number(value)
            vswr = ((Math.pow(10, (rl / 20)) + 1) / (Math.pow(10, (rl / 20)) - 1)).toFixed(2) + ' : 1'
            re = Math.pow(10, (rl / -20)).toFixed(5)
            break
        case 'rc':
            re = Number(value) 
            vswr = ((1 + re) / (1 - re)).toFixed(2) + ' : 1'
            rl = (-20 * Math.log10(re)).toFixed(1)
            break
        default: break
    }

    const units = [
        { name: 'VSWR', value: "vswr", defaultValue: vswr },
        { name: 'Return Loss (dB)', value: 'RL', defaultValue: rl },
        { name: 'Reflction Coefficient (Γ)', value: 'rc', defaultValue: re }
    ]

    return units
}