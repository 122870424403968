import React, { Component } from 'react'
import { Badge, Container, Col, Form, Jumbotron, Row } from 'react-bootstrap'
import { frequency_units } from '../../../constant'
import { FrequencyConversionHelper } from '../../../helpers/toolHelpers'


class FrequencyConversion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            freq_primary: 'Hz',
            freq_secondary: 'kHz',
            frequency: '',
            frequency_converted: ''
        }
    }

    badgeHandler(unit, order) {
        let frequency
        const primary = order === 'primary' ? unit : this.state.freq_primary
        const secondary = order === 'primary' ? primary === 'Hz' ? this.state.freq_secondary === 'Hz' ? 'kHz' : this.state.freq_secondary : this.state.freq_secondary : unit 

        frequency = FrequencyConversionHelper(this.state.frequency, primary, secondary).toExponential()

        this.setState({
            frequency_converted: frequency,
            freq_primary: primary,
            freq_secondary: secondary
        })
    }

    convertHandler(event) {
        const frequency = event.target.value
        let converted

        this.setState({ frequency: event.target.value })
        converted = FrequencyConversionHelper(frequency, this.state.freq_primary, this.state.freq_secondary).toExponential()
        this.setState({ frequency_converted: converted })
    }

    render () {
        return (
            <>
                <Container>
                    <Jumbotron>
                        <h4 className="rf-text-blu">Frequency Unit Conversion</h4>
                        <Row className="mt-5">
                            <Col>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Frequency (<i>f</i>)({this.state.freq_primary})</Form.Label>
                                        <Form.Control 
                                            type="number" 
                                            placeholder="Enter Frequency"
                                            onChange={this.convertHandler.bind(this)} 
                                        />
                                        <div className="my-3">
                                            {frequency_units.filter(freq => freq !== this.state.freq_secondary).map((units, index) => (
                                                <Badge 
                                                    key={index} 
                                                    className="mx-1 p-2 pointer"
                                                    variant={ this.state.freq_primary === units ? "info" : "secondary" }
                                                    onClick={ () => this.badgeHandler(units, 'primary') }
                                                >
                                                        {units}
                                                </Badge>
                                            ))}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mt-5">
                                        <Form.Label>Frequency (<i>f</i>)({this.state.freq_secondary})</Form.Label>
                                        <Form.Control 
                                            value={this.state.frequency_converted}
                                            readOnly
                                        />
                                        <div className="my-3">
                                            {frequency_units.filter(freq => freq !== this.state.freq_primary).map((units, index) => (
                                                <Badge 
                                                    key={index} 
                                                    className="mx-1 p-2 pointer"
                                                    variant={ this.state.freq_secondary === units ? "info" : "secondary" }
                                                    onClick={ () => this.badgeHandler(units, 'secondary') }
                                                >
                                                        {units}
                                                </Badge>
                                            ))}
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Jumbotron>
                </Container>
            </>
        )
    }
}

export default FrequencyConversion