import React, { Component } from 'react'
import { 
    Accordion, 
    Card, 
    Col,
    Container, 
    Image,
    Jumbotron,
    Row
} from 'react-bootstrap'
import image1 from '../../assets/images/getting-started/getting-started-1.2.png'
import image2 from '../../assets/images/getting-started/getting-started-2.2.png'
import image3 from '../../assets/images/getting-started/getting-started-3.2.png'

class GetStarted extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col md={12} className="text-center">
                        <h4>Getting Started</h4>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col lg={4} md={12} className="text-center">
                        {/* <h4>Getting Started</h4> */}
                        <Image 
                            src={image1} 
                            className="rfbackdoor-getting-started-image"
                        />
                        {/* <p><b>1.</b>Select a Category</p> */}
                    </Col>
                    <Col lg={4} md={12} className="text-center">
                        <Image 
                            src={image2} 
                            className="rfbackdoor-getting-started-image"
                        />
                        {/* <p><b>2.</b>Filter the Results</p> */}
                    </Col>
                    <Col lg={4} md={12} className="text-center">
                        <Image 
                            src={image3} 
                            className="rfbackdoor-getting-started-image"
                        />
                        {/* <p><b>3.</b>View More Details</p> */}
                    </Col>
                </Row>
                {/* <Row className="my-3">
                    <Col md={12} className="text-center">
                        <Image src={image2} className="rfbackdoor-getting-started-image"/>
                        <p>Use the filters that will appear above to narrow down the results list.</p>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col md={12} className="text-center">
                        <Image src={image3} className="rfbackdoor-getting-started-image"/>
                        <p>Select from the search results to see more information.</p>
                    </Col>
                </Row> */}
            </Container>
        )
    }
}

export default GetStarted