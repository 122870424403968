import React, { Component, Fragment } from 'react'
import {Col, Row } from 'react-bootstrap'
import { Instagram } from 'react-content-loader'

class GridLoad extends Component {

    loadAnimation() {
        let content = []

        for (let i = 0; i < 3; i++) {
            content.push(
                <Col lg={12} xl={4} key={i}>
                    <Instagram />
                </Col>
            )
        }

        return content
    }

    render() {
        return (
            <Fragment>
                <Row className="w-100 mx-2">
                    {this.loadAnimation()}
                </Row>
                <Row className="w-100 mx-2">
                    {this.loadAnimation()}
                </Row>
                <Row className="w-100 mx-2">
                    {this.loadAnimation()}
                </Row>
                <Row className="w-100 mx-2">
                    {this.loadAnimation()}
                </Row>
            </Fragment>
        )
    }
}

export default GridLoad