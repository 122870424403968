import React, { Component } from 'react'
import { withRouter } from "react-router"
import { Button, Container, Col, Image, Row } from 'react-bootstrap'
import Navigation from '../Navigation'
import Footer from '../subcomponents/Footer'
import { calculator_tools } from '../../constant'

class Tools extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    render () {
        // const { match } = this.props
        // const tool = typeof match.params.title ? match.params.title : 'default'

        return (
            <>
                <Navigation/>
                <Container
                    className="my-5"
                >
                {
                    calculator_tools.map((toolitem, index) => (
                        <Container 
                            key={index}
                            className="box round p-4 my-3"
                        >
                            <Row>
                                <Col 
                                    className="d-flex justify-content-center mt-4" 
                                    lg={4}
                                >
                                    <Image 
                                        className="rfbackdoor-tools-image border" 
                                        src={toolitem.image ? toolitem.image : require('../../assets/images/no-result.png').default}
                                    />
                                </Col>
                                <Col
                                    className="pt-5"
                                    lg={8}
                                >
                                    <h5 className="rf-text-brw">
                                        {toolitem.name}
                                    </h5>
                                    <p>
                                        {toolitem.description}
                                    </p>
                                    <Button 
                                        href={`/tools/${toolitem.value}`}
                                        variant="rfbackdoor-round"
                                        className="my-2"
                                    >
                                            Try calculator
                                        </Button>
                                </Col>
                            </Row>
                        </Container>
                    ))
                }
                </Container>
                <Footer/>
            </>
        )
    }
}

export default withRouter(Tools)