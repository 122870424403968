import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

class Footer extends Component {
    render() {
        return (
            <Container className="mt-5 p-4 text-center rfbackdoor-dark-bg rf-text-gry" fluid>
                © 2022 Copyright RFBackDoor.com
            </Container>
        )
    }
}

export default Footer