import React, { Component } from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import {withRouter} from 'react-router-dom'

import logo from '../assets/images/RfBackdoor.png'

class Navigation extends Component {

    render () {
        return (
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="box">
                <Container>
                    <Navbar.Brand href="/">
                        <img alt="rfbackdoor" src={logo} className="rfbackdoor-brand d-inline-block align-top"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav>
                            <Nav.Link href="/products" 
                                className={     
                                    this.props.location.pathname.includes('/products')
                                        ? 'rfbackdoor-navlink active' 
                                        : 'rfbackdoor-navlink' 
                                    }>
                                 Products 
                            </Nav.Link>
                            <Nav.Link eventKey={2} href="/manufacturers" 
                                className={ 
                                    this.props.location.pathname === '/manufacturers' 
                                        ? 'rfbackdoor-navlink active' 
                                        : 'rfbackdoor-navlink' 
                                    }>
                                 Manufacturers 
                            </Nav.Link>
                            <Nav.Link eventKey={3} href="/tools" 
                                className={ 
                                    this.props.location.pathname === '/tools'
                                        ? 'rfbackdoor-navlink active' 
                                        : 'rfbackdoor-navlink' 
                                    }>
                                 Tools 
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default withRouter(Navigation)