import React, { Component, Fragment } from 'react'
import {Col, Row } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'

class Custom extends Component {

    constructor(props) {
        super(props)
            this.state= {

        }
    }

    loadAnimation() {
        let content = []

        for (let i = 0; i < 9; i++) {
            content.push(
                <Col xl={4} lg={6} md={12}>
                    <ContentLoader height={280} width={465} >
                        <rect x="3" y="3" rx="10" ry="10" width="360" height="180" />
                        <rect x="6" y="190" rx="0" ry="0" width="292" height="20" />
                        <rect x="4" y="215" rx="0" ry="0" width="239" height="20" />
                        <rect x="4" y="242" rx="0" ry="0" width="274" height="20" />
                    </ContentLoader>
                </Col>
            )
        }

        return content
    }

    render () {
        return (
            <Fragment>
                <Row className="my-4 justify-content-center w-100 mx-2">
                    {this.loadAnimation()}
                </Row>
            </Fragment>
        )
    }
}
export default Custom