import React, { Component, Fragment } from 'react'
import { withRouter } from "react-router"
import Navigation from '../Navigation'
import axios from 'axios'
import { Button, Col, Container, Image, Row, Table } from 'react-bootstrap'
import Footer from '../subcomponents/Footer'
import noImage from '../../assets/images/no-image_2.png'
import SingleProduct from '../subcomponents/LoadingAnimation/SingleProduct'

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product_id: null,
            product: {},
            description: [],
            specifications: [],
            loading: false
        }
    }

    getProduct(id) {
        this.setState({ loading: true })
        axios.get(`/v1/api/products/${id}/`).then(response => {
            console.log(response.data)
            this.setState({ 
                product: response.data,
                description: response.data.specifications.filter(specification => specification.specification === 1),
                specifications: response.data.specifications,
                loading: false
            })
        })
    }

    componentDidMount() {
        this.getProduct(this.props.match.params.id)
    }

    render() {
        return (
            <Fragment>
                <Navigation/>
                {
                    this.state.loading ?
                        <Container
                            className="d-flex justify-content-center align-items-center"
                        >
                            <SingleProduct/>
                        </Container>
                    : <Container className="my-5 p-4">
                        <Row>
                            <Col className="d-flex flex-column align-items-center" 
                                lg={6} 
                                md={12}
                            >
                                {
                                    this.state.product.image_link ?
                                        <Image 
                                            width='80%' 
                                            src={this.state.product.image_link}
                                        />
                                    : <Image 
                                        width='200px' 
                                        src={noImage}
                                    />
                                }
                            </Col>
                            <Col className="d-flex flex-column" lg={6} md={12}>
                                <h3 
                                    className="rf-text-brw"
                                >
                                    {this.state.product.product_partnumber}
                                </h3>
                                <a 
                                    href={this.state.product.manufacturer_details && this.state.product.manufacturer_details.url}
                                    target="blank"
                                >

                                    {this.state.product.manufacturer_details && this.state.product.manufacturer_details.name}
                                    {/* <Image 
                                        width='100px' 
                                        src={this.state.product.manufacturer_details ? this.state.product.manufacturer_details.deets[0].logo_link : noImage}
                                    /> */}
                                </a>
                                <div className="my-5">
                                    <p>
                                        <b>
                                            <i>Description </i>
                                        </b>
                                    </p>
                                    
                                    {
                                        this.state.description.length ?
                                            this.state.description.map((desc, idx) => (
                                                <p key={idx}>
                                                    {desc.value}
                                                </p>
                                            ))
                                        : <p><i>No description available.</i></p>
                                    }
                                </div>
                                <div>
                                    
                                </div>
                                {
                                    this.state.product.pdf_link &&
                                        <Button 
                                            className="w-50"
                                            variant="rfbackdoor-round" 
                                            block href={this.state.product.pdf_link} 
                                            target="_blank"
                                        >
                                            View Datasheet
                                        </Button>
                                }
                                <div>
                                    {
                                        this.state.product.product_link &&
                                            <Button 
                                                className="mt-2 w-50"
                                                variant="rfbackdoor-round-outline" 
                                                block href={this.state.product.product_link} 
                                                target="_blank"
                                            >
                                                View Product Page
                                            </Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-5">
                            <Col>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Specification</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.specifications.map((specs, idx) => (
                                                <tr
                                                    key={idx}
                                                >
                                                    <td>
                                                        {specs.specification_details.name}
                                                    </td>
                                                    <td>
                                                        {specs.value}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                }
                <Footer/>
            </Fragment>
        )
    }
}

export default withRouter(Product)